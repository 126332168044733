import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Testpage() {
  const history = useHistory();
  const [files, setFiles] = useState();
  //const{prof, setprof} = useContext(profile);
  //console.log(user);
  //const[token, setToken] = useState({username});

  const testJson = async() => {
    await axios({
      method: 'get',
      url: "https://sharedspaces-dev.usask.ca/devroute/testjson"
    }).then(function(response){
      console.log(response.data.Message);
    }).catch(function(error){
      console.log(error);
    });
  }

  const sendFiles = async() => {
    const uploadForm = new FormData(); 
    uploadForm.append('name', 'testingname');
    uploadForm.append('author', 'testingauthor');
    for(var z = 0; z < files.length; z++){
      uploadForm.append('itemfn', files[z]);
    }
    await axios({
      method: 'post',
      url: "https://sharedspaces-dev.usask.ca/devroute/testzip", 
      headers: {
          'Content-Type': 'multipart/form-data',
        },
      data: uploadForm
    }).then(function(response){
      console.log(response);
    }).catch(function(error){
      console.log(error);
    });
  }
//<td className='operation'><button onClick={() => editInfo(itemname)}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>

  return (
      <div>
        <ToastContainer />
        <div>
          <button className="back-btn" onClick={()=>{history.goBack();}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
        </div>
        <h1 className="title">Test Page</h1>
        <input type="file" id="fileupload" multiple onChange={e => setFiles(e.target.files)} />
        <button onClick={sendFiles}>Upload</button>
        <button onClick={testJson}>Test JSON</button>
      </div>
  );
}

export default Testpage;