import React from 'react';  
import './Panel.css';  

const Panel = props => {
  return (
    <div className="root-panel">
      <table id='items'>
        <thead>
          <tr>{props.generateHeader()}</tr>
        </thead>
        <tbody>
          {props.generateBody()}
        </tbody>
      </table>
    </div>
  );
};
 
export default Panel;