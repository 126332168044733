import React, { useContext, useEffect, useState, useRef } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {isauth, usn} from '../Helper/Context';
import Menu from './Menu';
import Panel from './Panel';
import EditPanel from './EditPanel';
import Tag from './Tag';

//this uses the testing DBs. update to live DBs when pushing.

function Exhibitions() {
  const history = useHistory();
  const{ au } = useContext(isauth);
  const { mode } = useContext(usn);
  const [ismenu, setisMenu] = useState(false);
  useEffect(() => {
    if(au === "admin"){
      getitems();
    }
  }, [au]);
  const[exhibitionslist, setExhibitionslist] = useState([]);
  const[artworkslist, setArtworks] = useState(null);
  const[artistslist, setArtists] = useState(null);
  const[exhibitionsdisplay, setDisplay] = useState(true);
  const props = useRef({
      state: '',
      id: '',
      exhibitionname: '',
      galleries: '',
      shortdesc: '',
      longdesc: '',
      exhibitionpic: '',
      displaystatus: false
    }); 

  const[exhibitionsiconurl, setExhibitioniconurl] = useState(process.env.PUBLIC_URL + '/select.png');
  const[selectedicon, setSelected]= useState();
  const[exhibitionsicon, setExhibitionicon] = useState(null);
  useEffect(() => {
      if(exhibitionsicon && exhibitionsicon.length > 0){
        var updtmessage = "Selected " + exhibitionsicon[0].name;
        setSelected(updtmessage);
      } else {
        setSelected('');
      }
    }, [exhibitionsicon]);

  /* Submits a form to the server to request information on all the exhibitions in the exhibitions db. Data is returned in JSON form.
   */
  const getitems = async() => {
      await axios({
        method: 'post',
        url:`https://sharedspaces-dev.usask.ca/${mode}/dbrequests`,
        headers: {'Content-Type': 'application/json'},
        data: { reqtype: "exhibitions"}
      }).then(function(response){
          setExhibitionslist(response.data);
      }).catch(function (error) {
          toast.warn('Error connecting to the server.');
      });
  }

  /* Submits a form to the server to delete an exhibition from the exhibition db.
   * param string itemtodelete: name of exhibition to delete.
   */
  const removeData = (itemtodelete) => { 
      var itemfilter = exhibitionslist.filter(item => itemtodelete === item.exhibitionName);
      let iconname = itemfilter[0].exhibitionpic + '\texhibitionspic';
      axios({
          method: 'post',
          url: `https://sharedspaces-dev.usask.ca/${mode}/dashdelete`,
          headers: {'Content-Type': 'application/json'},
          data: {
          deletetype: "exhibitiondelete",
          fndelete: '',
          dbitem: itemtodelete,
          icdelete: iconname,
          texdelete: '',
          id: itemfilter[0].id
      }})
      .then(function (response) {
          const del = exhibitionslist.filter(upload => itemtodelete !== upload.exhibitionName);
          setExhibitionslist(del);
          toast.success(itemtodelete + ' Deleted');
          })
      .catch(function (error) {
          toast.warn('Delete failed.');
      });
  }

  /* Submits a form to the server to save changes to the fields in the exhibition page's instance of EditPanel. Grabs the current values in all the fields.
   */
  const saveData = async() => {
    const tosave = new FormData(); 
    if (props.current.state === "new") {
      tosave.append('updatetype', "newexhibition");
      let arr = [props.current.exhibitionname, props.current.shortdesc, props.current.longdesc, exhibitionsicon];
      let empty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
      if(!arr.every(empty)){
          toast.warn('Please ensure every input field is completed and an icon is selected.');
          return
        }
    } else {
      tosave.append('updatetype', "exhibition");
    }
    if (exhibitionsicon && exhibitionsicon.length > 0){
      tosave.append('exhibitionpic', exhibitionsicon[0].name);
      tosave.append('exhibitionfn', exhibitionsicon[0]);
    }
    tosave.append('dblookup', 'exhibitions');
    tosave.append('exhibitionName', props.current.exhibitionname);
    tosave.append('galleries', props.current.galleries);
    tosave.append('shortdesc', props.current.shortdesc);
    tosave.append('longdesc', props.current.longdesc);
    tosave.append('artworks', artworkslist.join('\t'));
    tosave.append('artists', artistslist.join('\t'));
    tosave.append('display', props.current.displaystatus);
    tosave.append('id', props.current.id);
    await axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/dashmodify`, 
      headers: {
          'Content-Type': 'multipart/form-data',
        },
      data: tosave
    }).then(res => {
      if(exhibitionsicon && (exhibitionsicon[0].name !== props.current.exhibitionpic)){
        var newprofpicadd = "https://sharedspaces-dev.usask.ca/app2/serve/exhibitionspic/" + res.data.append + exhibitionsicon[0].name.replace(/\s/g, "");
        setExhibitioniconurl(newprofpicadd);
      }
      toast.success('Exhibition info saved.');
      getitems();
      setExhibitionicon(null);
    })
    .catch(err => {
      toast.warn('Save failed.');
    });
  }

  /* Generates the headers in the table that displays all the exhibitions in the exhibition db.
   */
  const renderHeader = () => {
  let headerElement = ['Name', 'Galleries', 'Display Status', 'Edit', 'Delete'];

  return headerElement.map((key, index) => {
      return <th key={index}>{key}</th>
  })
  }

  /* Generates the rows in the table that displays all the exhibitions in the exhibition db.
   */
  const renderBody = () => {
    //return uploads && uploads.map(({ id, itemname, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, tags, modifyingitems, itemfilename}) => {
    return exhibitionslist && exhibitionslist.map(({ id, exhibitionName, galleries, short_desc, long_desc, artworks, artists, exhibitionpic, display_status}) => {
        return (
            <tr key={id}>
                <td>{exhibitionName}</td>
                <td>{galleries}</td>
                <td>{display_status}</td>
                <td className='operation'><button onClick={()=>{
                  if (artists.length > 0) {
                    setArtists(artists.trim().split('\t'));
                  }
                  if (artworks.length > 0) {
                    setArtworks(artworks.trim().split('\t'));
                  }
                  props.current.state = 'edit';
                  props.current.id = id;
                  props.current.exhibitionname = exhibitionName;
                  props.current.galleries = galleries;
                  props.current.shortdesc = short_desc;
                  props.current.longdesc = long_desc;
                  props.current.exhibitionpic = exhibitionpic;
                  props.current.displaystatus = display_status;
                  var exhibitionurl = "https://sharedspaces-dev.usask.ca/app2/serve/exhibitionspic/" + exhibitionpic;
                  setExhibitioniconurl(exhibitionurl);
                  setDisplay(false);
                }}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>
                <td className='operation'><button onClick={() => removeData(exhibitionName)}><img src={process.env.PUBLIC_URL + '/delete.png'} alt="Edit" width='15px'/></button></td>
            </tr>
        )
    })
  }

  return (
      <div>
        <button className="menu-btn" onClick={() => setisMenu(!ismenu)}><img src={process.env.PUBLIC_URL + '/menu.png'} alt="Menu" width='35px'/></button>
        {ismenu &&  <Menu active={"exhibitions"} menuis={ismenu} setMenuis={setisMenu} handleClose={() => setisMenu(!ismenu)} />}
        <ToastContainer />
        <div>
          <button className="back-btn" style={!exhibitionsdisplay ? { marginTop: 'calc(3vh + 35px)' } : { marginTop: '0' }} onClick={()=>{if (exhibitionsdisplay){history.goBack();} else {setDisplay(true); setExhibitionicon(null); setExhibitioniconurl(process.env.PUBLIC_URL + '/select.png');}}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
        </div> 
        {exhibitionsdisplay ? <h1 className="title">Exhibitions</h1> : <h1 className="title">Edit Exhibition</h1>}
        {exhibitionsdisplay &&  <Panel generateHeader={renderHeader} generateBody={renderBody} />}
        {exhibitionsdisplay && <button className="btn" onClick={()=>{props.current.state = "new"; setDisplay(false);}}>Create New Exhibition</button>}
        {!exhibitionsdisplay && <EditPanel 
            content={<>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-name" >Name</label>
                    <input type="text" className="profiletextinput" defaultValue={props.current.state === "edit" ? props.current.exhibitionname : ''} id="exhibition-name" onChange={e => props.current.exhibitionname = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-galleries" >Galleries</label>
                    <textarea className="profilelonginput" defaultValue={props.current.state === "edit"? props.current.galleries : ''} id="exhibition-galleries" onChange={e => props.current.galleries = e.target.value} required/>
                </div>
                <div className="div-padding-pic">
                    <p className="bold-label" >Exhibition Profile Pic</p>
                    <div className="profilepiccontainer">
                        <input type="file" id="profile-pic" accept="image/png, image/jpeg" onChange={e => setExhibitionicon(e.target.files)} required/>
                        <label className="profilefileinput" for="profile-pic"><img src={exhibitionsiconurl} alt="Profile icon preview." width="100px"/></label>
                        <p className="inputtext">{selectedicon}</p>
                    </div>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-bio-short" >Short Description</label>
                    <textarea className="profilelonginput" defaultValue={props.current.state === "edit"? props.current.shortdesc : ''} id="exhibition-bio-short" onChange={e => props.current.shortdesc = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-bio-long" >Long Description</label>
                    <textarea className="profilelonginput" defaultValue={props.current.state === "edit"? props.current.longdesc : ''} id="exhibition-bio-long" onChange={e => props.current.longdesc = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-artists" >Artists</label>
                    <Tag tagitems={artistslist} updateTags={setArtists} status={props.current.state} displayStatus={exhibitionsdisplay}/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="exhibition-artworks" >Artworks</label>
                    <Tag tagitems={artworkslist} updateTags={setArtworks} status={props.current.state} displayStatus={exhibitionsdisplay}/>
                </div>
            </>}
        />}
        {!exhibitionsdisplay && <button className="btn" onClick={()=> saveData()}>Save</button>}
      </div>
  );
}

export default Exhibitions;