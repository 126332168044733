import React, { useContext, useState, useEffect, useRef } from 'react';
import './Editor.css';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Unity, { UnityContext } from 'react-unity-webgl';

import {usn, profile} from '../Helper/Context';
/*const buildjson = process.env.PUBLIC_URL + "/build/platform.json";
const buildloader = process.env.PUBLIC_URL + "/build/platform.loader.js";
const unityContent = new UnityContent(
  buildjson,
  buildloader, {
    modules: {
      CachedXMLHttpRequestDisable: true
    }
  }
);*/

const unityContent = new UnityContext({
  loaderUrl: process.env.PUBLIC_URL + "/build/platform.loader.js",
  dataUrl: process.env.PUBLIC_URL + "/build/platform.data",
  frameworkUrl: process.env.PUBLIC_URL + "/build/platform.framework.js",
  codeUrl: process.env.PUBLIC_URL + "/build/platform.wasm",
  modules: {
    CachedXMLHttpRequestDisable: true
  }
})

function Editor() {
  useEffect(()=>{
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    document.documentElement.style.setProperty('--scrollbar-height', (window.innerHeight - document.documentElement.clientHeight) + "px");
  });
  /*
  const [newwindowwidth, setnewwindowwidth] = useState(document.documentElement.clientWidth/3 + 'px');
  const [newwindowheight, setnewwindowheight] = useState(document.documentElement.clientHeight + 'px');
  useEffect(()=>{
    function handleResize(){
      //document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
      //document.documentElement.style.setProperty('--scrollbar-height', (window.innerHeight - document.documentElement.clientHeight) + "px");
      setnewwindowwidth(document.documentElement.clientWidth/3 + 'px');
      setnewwindowheight(document.documentElement.clientHeight + 'px');
    }
    window.addEventListener('resize', handleResize);
  });
  
  const toplevel = useRef(null);
  const [newwindowwidth, setnewwindowwidth] = useState(null);
  const [newwindowheight, setnewwindowheight] = useState(null);
  useEffect(()=>{
    function handleResize(){
      setnewwindowwidth(document.getElementById('topwrap').clientWidth/3 + 'px');
      setnewwindowheight(document.getElementById('topwrap').clientHeight + 'px');
    }
    window.addEventListener('resize', handleResize);
  });*/
  const{user} = useContext(usn);
  const {prof} = useContext(profile);
  const{mode} = useContext(usn);
  const props = useRef({
    state: '',
    texture: '',
    baked: '',
    transparent: '',
    videomesh: '',
    audioabout: '',
    filename: '',
    iconstate: '',
    itemformat: '',
    loaded: 0,
    title: '',
    desc: '',
    id: '',
    size: '',
    xVal: '',
    yVal: '',
    zVal: '',
    xRot: '',
    yRot: '',
    zRot: '',
    toastContainer: null
  }); 

  const [displaySave, setDisplaySave] = useState(false);
  const [itemname, setitemname] = useState("Type something...");
  const [itemdescription, setitemdescription] = useState("Type something...");
  const [files, setFiles] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [iconPic, seticonPic] = useState(null);
  const [iconUrl, setIconUrl] = useState(process.env.PUBLIC_URL + '/img_prv.jpg');
  const [selectedIcon, setSelectedIcon] = useState("");
  
  const [audio, setAudio] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [selectedAudio, setSelectedAudio] = useState("");

  const [texture, setTexture] = useState(null);
  const [textureUrl, setTextureUrl] = useState(process.env.PUBLIC_URL + '/img_prv.jpg');
  const [selectedTexture, setSelectedTexture] = useState("");

  const [status, setStatus] = useState(false);
  useEffect(() => {
    if (status === true) {
      props.current.toastContainer = toast.info("Loading...", {autoClose: false});
    }
    if(status === false){
      toast.dismiss(props.current.toastContainer);
    }
  }, [status]);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(()=>{
    unityContent.on("loaded", function(){
      setIsLoaded(true);
    });
  }, []);


  const [model3d_tag, setmodel3d_tag] = useState(false);
  const [animation_tag, setanimation_tag] = useState(false);
  const [sound_tag, setsound_tag] = useState(false);

  const [allrights_tag, setallrights_tag] = useState(false);
  useEffect(() => {
    if (allrights_tag === true){
      setuniversal_tag(false);
      setattributionshare_tag(false);
      setattributionnoncom_tag(false);
    }
  }, [allrights_tag]);
  const [universal_tag, setuniversal_tag] = useState(false);
  useEffect(() => {
    if (universal_tag === true){
      setallrights_tag(false);
      setattributionshare_tag(false);
      setattributionnoncom_tag(false);
    }
  }, [universal_tag]);
  const [attributionshare_tag, setattributionshare_tag] = useState(false);
  useEffect(() => {
    if (attributionshare_tag === true){
      setallrights_tag(false);
      setuniversal_tag(false);
      setattributionnoncom_tag(false);
    }
  }, [attributionshare_tag]);
  const [attributionnoncom_tag, setattributionnoncom_tag] = useState(false);
  useEffect(() => {
    if (attributionnoncom_tag === true){
      setallrights_tag(false);
      setuniversal_tag(false);
      setattributionshare_tag(false);
    }
  }, [attributionnoncom_tag]);

  const backButtonAction = async() => {
    await unityContent.quitUnityInstance();
    history.goBack(); 
  }
    /*Sends a command to the Unity app to change the size of the 3d model
   * param float newsizenum: the new size value.
   */
    const changeSize = (newsizenum) => {
      unityContent.send("scriptManager", "adjustSize", newsizenum);
    }
  
    /* Sends a command to the Unity app to change the rotation of the 3d model
     * param float newX: the new x value.
     * param float newY: the new y value.
     * param float newZ: the new z value.
     */
    const changeRot = (newX, newY, newZ) => {
      var newstring = newX + ' ' + newY + ' ' + newZ;
      unityContent.send("scriptManager", "adjustRot", newstring);
    }
  
    /*Sends a command to the Unity app to change the positioning of the 3d model
     * param float newX: the new x value.
     * param float newY: the new y value.
     * param float newZ: the new z value.
     */
    const changePos = (newX, newY, newZ) => {
      var newstring = newX + ' ' + newY + ' ' + newZ;
      unityContent.send("scriptManager", "adjustPos", newstring);
    }

  const history = useHistory();
  useEffect(() => {
    if(history){
      props.current.state = history.location.state;
      //itemfile, video, baked, transparency
      let fnArr= ["null", "null", "null", "null", "null", "null"];
      /*let jsonOBJ = [{
        itemUrl: "",
        videoUrl: "",
        transparencyUrl: "",
        bakedUrl: ""
      }];*/
      if(history.location.state === "new"){
        props.current.size = '1';
        props.current.xVal = '0';
        props.current.yVal = '0';
        props.current.zVal = '0';
        props.current.xRot = '0';
        props.current.yRot = '0';
        props.current.zRot = '0';
      } else {
        //var fn = history.location.fnstate;
        //var material = fn.substring(0, fn.indexOf(".")) + ".mtl";
        //var icurl = "https://sharedspaces-dev.usask.ca/app2/serve/artworkspic/" + history.location.iconstate;
        fnArr[5] = "loadingExisting";
        props.current.title = history.location.name;
        props.current.baked = history.location.bakedstate;
        props.current.videomesh = history.location.videomesh;
        props.current.desc = history.location.descstate;
        props.current.id = history.location.id;
        props.current.audioabout = history.location.audiofilename;
        props.current.itemformat = history.location.extension;
        props.current.texture = history.location.texture;
        props.current.size = history.location.sizestate;
        props.current.xVal = history.location.xValstate;
        props.current.yVal = history.location.yValstate;
        props.current.zVal = history.location.zValstate;
        props.current.xRot = history.location.xRotstate;
        props.current.yRot = history.location.yRotstate;
        props.current.zRot = history.location.zRotstate;
        props.current.iconstate = history.location.iconstate;
        props.current.filename = history.location.fnstate;
        let tagDBVals = (history.location.tags).split('\t');
        if (tagDBVals.indexOf('3D') !== -1){
          setmodel3d_tag(true);
        }
        if (tagDBVals.indexOf('Animated') !== -1){
          setanimation_tag(true);
        }
        if (tagDBVals.indexOf('Sound') !== -1){
          setsound_tag(true);
        }
        if(history.location.license === "allrights"){
          setallrights_tag(true);
        }
        if(history.location.license === "universalpub"){
          setuniversal_tag(true);
        }
        if(history.location.license === "attributionshare"){
          setattributionshare_tag(true);
        }
        if(history.location.license === "attributionnoncom"){
          setattributionnoncom_tag(true);
        }
        setitemname(history.location.name);
        setitemdescription(history.location.descstate);
        setSelectedFiles(`Selected ${history.location.fnstate}`);
        //props.current.selectedIcon = history.location.iconstate;
        //props.current.selectedAudio = history.location.audiofilename;
        //props.current.selectedVideo = history.location.videomesh;
        fnArr[4] = `${history.location.sizestate}~${history.location.xValstate} ${history.location.yValstate} ${history.location.zValstate}~${history.location.xRotstate} ${history.location.yRotstate} ${history.location.zRotstate}`;
        setSelectedIcon(`Selected ${history.location.iconstate}`);
        //jsonOBJ[0]["itemUrl"] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.fnstate}`;
        fnArr[0] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.fnstate}`;
        if (history.location.bakedstate.length > 2){
          props.current.transparent = false;
          //jsonOBJ[0]["bakedUrl"] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.baked}`;
          //props.current.selectedTexture = `Selected ${history.location.bakedstate}`;
          fnArr[2] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.bakedstate}`;
          setSelectedTexture(` Selected ${history.location.bakedstate}`);
          setTextureUrl(`https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.bakedstate}`);
        }
        if (history.location.texture.length > 2){
          props.current.transparent = true;
          //jsonOBJ[0]["transparencyUrl"] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.texture}`;
          //props.current.selectedTexture = `Selected ${history.location.texture}`;
          fnArr[3] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.texture}`;
          setSelectedTexture(` Selected ${history.location.texture}`);
          setTextureUrl(`https://sharedspaces-dev.usask.ca/app2/serve/items/${history.location.texture}`);
        }
        if (history.location.audiofilename.length > 2) {
          setSelectedAudio(` Selected ${history.location.audiofilename}`);
          setAudioUrl(`https://sharedspaces-dev.usask.ca/app2/serve/audiofiles/${history.location.audiofilename}`);
        }
        setIconUrl(`https://sharedspaces-dev.usask.ca/app2/serve/artworkspic/${history.location.iconstate}`);
      }
      unityContent.on("loaded", () => {
        if(history.location.state === "new"){
          setTimeout(() => {  destroyObj(); }, 700);
        } else if (history.location.state === "edit" || history.location.state === "review") {
          //var settingsString = fn + "\t" + material + "/~" + history.location.sizestate + "\t" + history.location.xValstate + ' ' + history.location.yValstate + ' ' + 
            //history.location.zValstate + "\t" + history.location.xRotstate + ' ' + history.location.yRotstate + ' ' + history.location.zRotstate;
          //unityContent.send("scriptManager", "universalLoading", JSON.stringify(jsonOBJ));
          //setTimeout(() => {  unityContent.send("scriptManager", "universalLoading", fnArr.join('\t')); }, 1500);
          const timeoutUnity = setTimeout(() => {
            unityContent.send("scriptManager", "universalLoading", fnArr.join('\t'));
          }, 1500);
          return ()=> clearTimeout(timeoutUnity);
          //setTimeout(() => { changeSize(history.location.sizestate); changeRot(history.location.xRotstate, history.location.yRotstate, history.location.zRotstate); changePos(history.location.xValstate, history.location.yValstate, history.location.zValstate);}, 10000);
        }
      });
    }
  }, [history]);

  if(!prof) {
    history.push('/profile');
  }

  /* Sends a command to the Unity app to delete the current 3d model. */
  const destroyObj = () => {
    unityContent.send("scriptManager", "deleteItems");
  }

  /* Sends an Axios request to either upload a new model or update an existing one. Appends the relevant fields such as positioning, rotational, and name to a form that is sent along with any files
   * relevant to the model. On a successful reply from the server, the Unity app is reloaded with the uploaded files to display. Relevant React hooks that held files are set to null.
   */
  const newupload = async() => {
    setStatus(true);
    const multiForm = new FormData();
    let updatetype = "";
    let tagStr = "";
    let rightsTag = "";
    multiForm.append('dblookup', 'items');
    multiForm.append('size', props.current.size);
    multiForm.append('xVal', props.current.xVal);
    multiForm.append('yVal', props.current.yVal);
    multiForm.append('zVal', props.current.zVal);
    multiForm.append('xRot', props.current.xRot);
    multiForm.append('yRot', props.current.yRot);
    multiForm.append('zRot', props.current.zRot);
    multiForm.append('artist', prof[0].artistName);
    multiForm.append('itemdesc', props.current.desc);
    multiForm.append('modifications', '');
    multiForm.append('modifyingitems', '');
    if (props.current.state === "new"){
      updatetype = updatetype + "new";
    } else if (props.current.state === "edit"){
      multiForm.append('id', props.current.id);
    }
    let tagStateArr = [model3d_tag, animation_tag, sound_tag];
    let tagValArr = ['3D', 'Animated', 'Sound'];
    for(var x = 0; x < tagStateArr.length; x++){
      if (tagStateArr[x] !== false){
        tagStr = tagStr + tagValArr[x] + '\t';
      }
    }
    if (tagStr.length > 0){
      tagStr = tagStr.substring(0, tagStr.length - 1);
    }
    let rightstagArr = [allrights_tag, universal_tag, attributionshare_tag, attributionnoncom_tag];
    let rightstagValue = ['allrights', 'universalpub', 'attributionshare', 'attributionnoncom'];
    for(var i = 0; i < rightstagArr.length; i++){
      if (rightstagArr[i] !== false){
        rightsTag = rightstagValue[i];
      }
    }
    if (files) {
      updatetype = updatetype + "files";
      for(var z = 0; z < files.length; z++){
        multiForm.append('itemfn', files[z]);
      }
    }
    if (iconPic){
      updatetype = updatetype + "iconpic";
      multiForm.append('iconfn', iconPic[0]);
    }
    if (audio){
      updatetype = updatetype + "audio";
      multiForm.append('audiofn', audio[0]);
    }
    if (texture){
      updatetype = updatetype + "baked";
      multiForm.append('bakedfn', texture[0]);
    }
    multiForm.append('itemname', props.current.title);
    multiForm.append('uploader', user);
    multiForm.append('tags', tagStr);
    multiForm.append('license', rightsTag);
    multiForm.append('updatetype', updatetype);
    await axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/asyncupdating`, 
      headers: {
          'Content-Type': 'multipart/form-data',
        },
      data: multiForm,
      onUploadProgress: ProgressEvent => { 
        props.current.loaded = (ProgressEvent.loaded / ProgressEvent.total*100);
      }
    }).then(function (response) {
      //itemfile, video, baked, transparency
      let filename = (props.current.filename.length > 2) ? `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.filename}`: "null";
      let texfilename = (props.current.baked.length > 2) ? `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.baked}` : "null";
      let fnArr = [filename, "null", texfilename, "null", `${props.current.size}~${props.current.xVal} ${props.current.yVal} ${props.current.zVal}~${props.current.xRot} ${props.current.yRot} ${props.current.zRot}`, "null"];
      if (props.current.state === "new"){
        //var msg = (response.request.response).trim();
        props.current.id = response.data.id;
      }
      toast.success('Upload successful.');
      if (files){
        props.current.filename = `${(response.data.append + props.current.title).replace(/\s/g, "").replace(/%20/g, "")}.zip`;
        fnArr[0] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${(response.data.append + props.current.title).replace(/\s/g, "").replace(/%20/g, "")}.zip`;
      } else {
        fnArr[0] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.filename}`;
      }
      if(iconPic){
        props.current.iconstate = (response.data.append +  iconPic[0].name).replace(/\s/g, "").replace(/%20/g, "");
        setIconUrl(`https://sharedspaces-dev.usask.ca/app2/serve/artworkspic/${(response.data.append +  iconPic[0].name).replace(/\s/g, "").replace(/%20/g, "")}`);
      }
      if(audio){
        props.current.audioabout = (response.data.append + audio[0].name).replace(/\s/g, "").replace(/%20/g, "");
        setAudioUrl(`https://sharedspaces-dev.usask.ca/app2/serve/audiofiles/${(response.data.append + audio[0].name).replace(/\s/g, "").replace(/%20/g, "")}`);
      }
      if (texture){
        fnArr[2] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${(response.data.append + texture[0].name).replace(/\s/g, "").replace(/%20/g, "")}`;
        props.current.baked = (response.data.append + texture[0].name).replace(/\s/g, "").replace(/%20/g, "");
        setTextureUrl(`https://sharedspaces-dev.usask.ca/app2/serve/items/${(response.data.append + texture[0].name).replace(/\s/g, "").replace(/%20/g, "")}`); 
      }
      if (files || texture) {
        console.log(fnArr);
        unityContent.send("scriptManager", "universalLoading", fnArr.join('\t'));
      }
    }).then(() => {
      if(props.current.state === "new"){
        props.current.state = "edit";
      }
      setFiles(null);
      seticonPic(null);
      setAudio(null);
      setTexture(null);
      setStatus(false);
      setDisplaySave(false);
    }).catch(function() {
      toast.warn('Upload failed.');
      setStatus(false);
    });
  }

  /* Removes baked textures or audio files that were previously uploaded. An Axios request is sent with the filename, id of the model, and the type of media to remove. The Unity app is called
   * on successful server reply to reload the app. The associated hook is also reset to null
   * param string filename 
   * param var filetype 
   * param method setfiletype 
   * param  fileselected 
   * param string updatetype 
   */
  const removeItem = async(filename, filetype, setfiletype, fileselected, updatetype) => {
    setStatus(true);
    if(filetype !== null && filetype.length > 0){
      setfiletype(null);
      fileselected("");
      if(filename.length > 2){
        fileselected(`Selected ${filename}`);
      }
      setStatus(false);
      toast.success("File removed.");
    } else {
      axios({
        method: 'post',
        url: `https://sharedspaces-dev.usask.ca/${mode}/deleteMedia`,
        headers: {'Content-Type': 'application/json'},
        data: {
          mediatype: updatetype,
          id: props.current.id,
          filename: filename
      }}).then(function (response) {
        setStatus(false);
        //itemfile, video, baked, transparency
        let dataArr = [`https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.filename}`, "null", "null", "null", `${props.current.size}~${props.current.xVal} ${props.current.yVal} ${props.current.zVal}~${props.current.xRot} ${props.current.yRot} ${props.current.zRot}`];
        if (props.current.videomesh.length > 2) {
          dataArr[1] = `https://sharedspaces-dev.usask.ca/app2/serve/artworkvideos/${props.current.videomesh}`;
        }
        if (props.current.baked.length > 2) {
          dataArr[2] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.baked}`;
        }
        if (props.current.texture.length > 2) {
          dataArr[3] = `https://sharedspaces-dev.usask.ca/app2/serve/items/${props.current.texture}`;
        }
        toast.success(updatetype + " deleted.");
        setfiletype(null);
        fileselected("");
        filename = ""
        if (updatetype === "audio"){
          setAudioUrl("");
        }
        if (updatetype !== "audio") {
          dataArr[2] = "null";
          setTextureUrl(process.env.PUBLIC_URL + '/img_prv.jpg');
          unityContent.send("scriptManager", "universalLoading", dataArr.join('\t'));
        }
      }).catch(function (error) {
        setStatus(false);
        toast.warn('Delete failed.');
      });
    }
  }

  /* Sends an Axios request to the server to set the review_status of the 3d model. Only accessible by admin accounts.
   * param string verdict: verdict for the entry. Either "true" or "ignore".
   */
  const decision = (verdict) => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/itemreview`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        itemid: props.current.id,
        item: props.current.title,
        account: history.location.uploadername,
        columnvalue: verdict
      }
    }).then(() => {
      toast.success("Saved.");
    }).catch(function (error) {
      toast.warn('Error connecting to the server.');
    });
  }

  /* Method that starts when the upload button is pressed. It checks to macke sure a license is selected, the relevant input fields are filled, and verifies each uploaded file to make sure
   * they are in the right format and are within size limits. 
   */
  const onClickHandler = async() => {
    try {
      const infoForm = new FormData();
      infoForm.append('itemName', props.current.title);
      infoForm.append('artist', props.current.artistName);
      let check_3d = await checkFiles(files, ['4B617964'], 3000000);
      let check_tex = await checkFiles(texture, ['FFD8FFE0', '89504E47', 'FFD8FFE2'], 1100000);
      let check_icon = await checkFiles(iconPic, ['FFD8FFE0', '89504E47', 'FFD8FFE2'], 11000);
      let check_audio = await checkFiles(audio, ['4944333'], 1100000);
      let check_previous = await axios.post("https://sharedspaces-dev.usask.ca/uploadconnect/checkitem.php", infoForm);
      if (allrights_tag === false && universal_tag === false && attributionshare_tag === false && attributionnoncom_tag === false){
        toast.warn("Please select a license to publish this artwork with."); 
        return
      }
      if (check_3d !== true){
        toast.warn("Please ensure the 3d model files is in fbx format and is under 3mb."); 
        return
      }
      if (check_tex !== true) {
        toast.warn("Please ensure the texture file is in jpeg/png format and is under 500kb.");
        return
      }
      if (check_audio !== true) {
        toast.warn("Please ensure the audio file is in mp3 format and is under 1mb.");
        return
      }
      if (check_previous.request.response.trim() !== "0"){
        toast.warn('A similarly named item by a similarly named artist already exists.');
        return
      }
      if (check_icon !== true){
        toast.warn('Please ensure the profile icon file is in jpeg/png format and is under 10kb.');
        return
      }
      if (check_3d === true && check_tex === true && check_audio === true && check_icon === true){
        if (props.current.state === 'new') {
          let items = [files, iconPic, props.current.title, props.current.desc];
          let empty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
          if(!items.every(empty)){
            toast.warn('Please ensure the name and description fields are completed as well as 3D object files and icon picture selected.');
            return
          } else {
            newupload();
          }
        } else if (props.current.state === 'edit') {
          if (props.current.title.length < 2 || props.current.desc.length < 2){
            toast.warn('Please ensure the name and description fields are filled out.');
            return
          } else {
            newupload();
          }
        }
      }
    } catch (e) {
      toast.warn(e);
    }
  }

  /* Verifies the provided files are in the correct format and are within the size limit.
   * param var varFile: the file that is being checked.
   * param string array varHex: hex value that the file has to match to be considered valid.
   * param int varLimit: the max file size allowed for the file.
   */
  const checkFiles = (varFile, varHex, varLimit) => {
    return new Promise((resolve) => {
      if (varFile === null || varFile.length < 1){
        resolve(true);
      } else {
        let reader = new FileReader();
        let blob = varFile[0].slice(0,4);
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
          const uint = new Uint8Array(reader.result);
          let bytes = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          })
          let hex = bytes.join('').toUpperCase();
          let matchvalue = 0;
          for(var v = 0; v < varHex.length; v++){
            if (hex === varHex[v]){
              matchvalue += 1;
            }
          }
          if (matchvalue < 1 || varFile[0].size > varLimit) {
            resolve(false);
          } else {
            resolve(true);
          }
        }
      } 
    })
  }

  const verifyAudioDuration = (event) => {
    let audiofr = document.createElement('audio');
    var target = event.currentTarget;
    var file = target.files[0];
    var reader = new FileReader();

    if (target.files && file) {
        var reader = new FileReader();

        reader.onload = function (e) {
          audiofr.src = e.target.result;
          audiofr.onloadedmetadata = function(){
              if(audiofr.duration > 35){
                toast.warn("Please ensure the audio file duration is under 30 seconds.");
              } else {
                setAudio(e.target.files);
                setSelectedAudio(`Selected ${e.target.files[0].name}`);
                if (displaySave === false){setDisplaySave(true);}
              }
            }
        };
        reader.readAsDataURL(file);
    }
  }
//<Unity unityContext={unityContent} style={{height: newwindowheight, width: newwindowwidth}}/>
/*
<div style={{height:'20%'}}>
          <p>Item 1</p>
          <input type="text" style={{height:'50%'}}/>
        </div>
 */
  return (
    <div id="topwrap">
      <div id="col1" className="columnSettings">
        <ToastContainer/>
        <div>
          <button className="back-btn" onClick={() => {backButtonAction();}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
          <p className="headline-text">Shared Spaces 3D Model Uploader</p>
        </div>
        <div>
          <p className="subheading-text">Artwork Name (Required)</p>
          <input type="text" disabled={status} value={itemname} onChange={e => {if (!status){props.current.title = e.target.value; setitemname(e.target.value); if (displaySave === false){setDisplaySave(true);}}}} required/>
        </div>
        <div>
          <p className="subheading-text">Artwork Description (Required)</p>
          <textarea className="longform-input" disabled={status} value={itemdescription} onChange={e => {if (!status) {props.current.desc = e.target.value; setitemdescription(e.target.value); if (displaySave === false){setDisplaySave(true);}}}} required/>
        </div>
        <p className="subheading-text">Artwork Profile Icon (Required)</p>
        <p style={{fontSize: '1.5vh'}}>Upload a jpeg (10 KB limit)</p>
        <div id="iconpic-upload" className="file-container">
          <div id="iconpic-label-upload-container" className="upload-container">
            {props.current.state !== "review" ? <input type="file" id="artwork-pic" disabled={status} accept="image/png, image/jpeg" onChange={e => {if (!status) {seticonPic(e.target.files); setSelectedIcon(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} required/> : null}
            <label id="artwork-pic-label" for="artwork-pic"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="iconpic-currently-selected-text" className="current-selection">
              <p className="selected-text">{selectedIcon}</p>
            </div>
          </div>
          <div id="profile-pic-preview" className="icon-prev">
            <img className="icon-prev-image" src={iconUrl} alt="Select audio files to narrate this piece."/>
          </div>
        </div>
        <p className="subheading-text">3D Object File (Required)</p>
        <p style={{fontSize: '1.5vh'}}>Upload an FBX (3 MB limit)</p>
        <div id="files-upload" className="file-container">
          <div id="files-label-upload-container" style={{maxWidth: "100%"}} className="upload-container">
            {props.current.state !== "review" ? <input type="file" id="fileupload" disabled={status} onChange={e => {if (!status) {setFiles(e.target.files); setSelectedFiles(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} /> : null}
            <label id="artwork-pic-label" for="fileupload"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="files-currently-selected-text" className="current-selection">
              <p className="selected-text">{selectedFiles}</p>
            </div>
          </div>
        </div>
        <p className="subheading-text">Image Texture File (Optional)</p>
        <p style={{fontSize: '1.5vh'}}>Upload a jpeg or png (500 KB limit)</p>
        <div id="texture-upload" className="file-container">
          <div id="texture-label-upload-container" className="upload-container">
          {props.current.state !== "review" ? <input type="file" id="texture-pic" disabled={status} accept="image/jpeg, image/png" onChange={e => {if (!status) {setTexture(e.target.files); setSelectedTexture(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} required/> : null}
            <label id="artwork-pic-label" for="texture-pic"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="texture-currently-selected-text" className="current-selection">
              {(selectedTexture !== "" && props.current.baked.length > 2) || (texture !== null && texture.length > 0) ? <button className="small-x" disabled={status} type="button" onClick={()=> {if (!status) {removeItem(props.current.baked, texture, setTexture, setSelectedTexture, "baked");}}}>x</button> : null}
              <p className="selected-text">{selectedTexture}</p>
            </div>
          </div>
          <div id="texture-preview" className="icon-prev">
            <img className="icon-prev-image" src={textureUrl} alt="Select jpeg files to apply baked textures to your 3d model."/>
          </div>
        </div>
      </div>
      <div id="col2" className="columnSettings">
        <Unity unityContext={unityContent} style={{width:'100%', height:'99.8%'}}/>
      </div>
      <div id="col3" className="columnSettings" style={{float:'right'}}>
      <p className="subheading-text">Audio File (Optional)</p>
        <p style={{fontSize: '1.5vh'}}>Upload an mp3 file (1 MB limit). Note: audio auto-plays and loops in-app.</p>
        <div id="audio-upload" className="file-container">
          <div id="audio-label-upload-container" style={{maxWidth: "100%", height: "5vw", display: 'inline-grid'}} className="upload-container">
            <div id="audio-top-container" style={{display:'flex', margin:'auto', marginLeft:'0'}}>
              {props.current.state !== "review" ? <input type="file" id="audioupload" disabled={status} accept="audio/mp3" onChange={e => {if (!status) {setAudio(e.target.files); setSelectedAudio(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} /> : null}
              <label style={{marginTop: '0.8vw'}} id="artwork-pic-label" for="audioupload"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
              <p style={{marginTop:'1.25vw'}} className="instruction">Click to select</p>
              <div id="audio-currently-selected-text" className="current-selection">
                {selectedAudio !== "" && (props.current.audioabout.length > 2 || audio.length > 0) ? <button className="small-x" style={{marginRight:'0.5vw'}} disabled={status} type="button" onClick={()=> {if (!status) {removeItem(props.current.audioabout, audio, setAudio, setSelectedAudio, "audio");}}}>x</button> : null}
                <p className="currently-selected-text">{selectedAudio}</p>
              </div>
            </div>
              <audio id="audio-playback" controls src={audioUrl}> Your browser does not support the<code>audio</code> element.</audio>
          </div>
        </div>
        <p className="subheading-text">Positioning Information</p>
        <p style={{fontWeight: '600', fontSize:'1.5vh'}}>Size</p>
        <input id="size" className="size-rot-input" disabled={status} defaultValue={props.current.size} type="text" onChange={e => {if (!status) {props.current.size = e.target.value; if(e.target.value.trim() !== ''){changeSize(e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        <p style={{fontWeight: '600', fontSize:'1.5vh'}}>Position</p>
        <div>
          <label for="xval" className="size-rot-label">X</label>
          <input id="xval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.xVal} onChange={e => {if (!status) {props.current.xVal = e.target.value; if(e.target.value.trim() !== ''){changePos(e.target.value, props.current.yVal, props.current.zVal);} if (displaySave === false){setDisplaySave(true);}}}} />
          <label for="yval" className="size-rot-label">Y</label>
          <input id="yval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.yVal} onChange={e => {if (!status) {props.current.yVal = e.target.value; if(e.target.value.trim() !== ''){changePos(props.current.xVal, e.target.value, props.current.zVal);} if (displaySave === false){setDisplaySave(true);}}}} /> 
          <label for="zval" className="size-rot-label">Z</label>
          <input id="zval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.zVal} onChange={e => {if (!status) {props.current.zVal = e.target.value; if(e.target.value.trim() !== ''){changePos(props.current.xVal, props.current.yVal, e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        </div>
        <p style={{fontWeight: '600', fontSize:'1.5vh'}}>Rotation</p>
        <div>
          <label for="xrot" className="size-rot-label">X</label>
          <input id="xrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.xRot} onChange={e => {if (!status) {props.current.xRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(e.target.value, props.current.yRot, props.current.zRot);} if (displaySave === false){setDisplaySave(true);}}}} />
          <label for="yrot" className="size-rot-label">Y</label>
          <input id="yrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.yRot} onChange={e => {if (!status) {props.current.yRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(props.current.xRot, e.target.value, props.current.zRot);} if (displaySave === false){setDisplaySave(true);}}}} /> 
          <label for="zrot" className="size-rot-label">Z</label>
          <input id="zrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.zRot} onChange={e => {if (!status) {props.current.zRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(props.current.xRot, props.current.yRot, e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        </div>
        <p className="subheading-text">Tags</p>
        <div>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={model3d_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setmodel3d_tag(true);} else {setmodel3d_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb1"/><label className="tags-button" for="cb1">3D Model</label>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={animation_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setanimation_tag(true);} else {setanimation_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb2"/><label className="tags-button" for="cb2">Animation</label>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={sound_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setsound_tag(true);} else {setsound_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb3"/><label className="tags-button" for="cb3">Sound</label>
        </div>
        <p className="subheading-text">License (Required)</p>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb1" disabled={status} checked={allrights_tag} onChange={e=>{if (!status) {setallrights_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>All Rights Reserved:</span> you are the full copyright holder</p>   
        </div>
        <div id="license-text-container2" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb2" disabled={status} checked={universal_tag} onChange={e=>{if (!status) {setuniversal_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Universal Public Domain (CC0):</span> You waive all rights to others can copy, modify, distribute the work</p>
        </div>
        <div id="license-text-container3" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb3" disabled={status} checked={attributionshare_tag} onChange={e=>{if (!status) {setattributionshare_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-ShareAlike (CC BY-SA):</span> Others can copy, modify, and redistribute work but must provide appropriate credit to the original creator; if work is modified the work must be distributed under the same CC BY-SA licence</p>        
        </div>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb4" disabled={status} checked={attributionnoncom_tag} onChange={e=>{if (!status) {setattributionnoncom_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-NonCommerical-ShareAlike (CC BY-NC-SA):</span> Others can copy, modify, and redistribute work but not for commercial purposes; they must give appropriate credit to the original creator and the work must be distributed under the same CC BY-NC-SA license</p>
        </div>
        <div>
          {props.current.state !== "review" && displaySave === true ? <button className="small-button" type="button" disabled={status} onClick={() => {if(!status){onClickHandler();}}}>Save</button> : null}
          {props.current.state === "review" ? <button style={{float: 'left'}} className="small-button" type="button" onClick={() => {decision("true");}}>Approve</button> : null}
          {props.current.state === "review" ? <button className="small-button" type="button" onClick={() => {decision("ignore");}}>Reject</button> : null}
        </div>
      </div>
    </div>   
  );
}
export default Editor;

/*
     <div>
      <div id="left-panel" className="columnSettings">
      <ToastContainer/>
        <div>
          <button className="back-btn" onClick={() => history.goBack()}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
          <p className="headline-text">Shared Spaces 3D Model Uploader</p>
        </div>
        <div>
          <p className="subheading-text">Artwork Name (Required)</p>
          <input type="text" disabled={status} value={itemname} onChange={e => {if (!status){props.current.title = e.target.value; setitemname(e.target.value); if (displaySave === false){setDisplaySave(true);}}}} required/>
        </div>
        <div>
          <p className="subheading-text">Artwork Description (Required)</p>
          <textarea className="longform-input" disabled={status} value={itemdescription} onChange={e => {if (!status) {props.current.desc = e.target.value; setitemdescription(e.target.value); if (displaySave === false){setDisplaySave(true);}}}} required/>
        </div>
        <p className="subheading-text">Artwork Profile Icon (Required)</p>
        <p>Upload a jpeg (10 KB limit)</p>
        <div id="iconpic-upload" className="file-container">
          <div id="iconpic-label-upload-container" className="upload-container">
            {props.current.state !== "review" ? <input type="file" id="artwork-pic" disabled={status} accept="image/png, image/jpeg" onChange={e => {if (!status) {seticonPic(e.target.files); setSelectedIcon(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} required/> : null}
            <label id="artwork-pic-label" for="artwork-pic"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="iconpic-currently-selected-text" className="current-selection">
              <p className="selected-text">{selectedIcon}</p>
            </div>
          </div>
          <div id="profile-pic-preview" className="icon-prev">
            <img className="icon-prev-image" src={iconUrl} alt="Select audio files to narrate this piece."/>
          </div>
        </div>
        <p className="subheading-text">3D Object File (Required)</p>
        <p>Upload an FBX (3 MB limit)</p>
        <div id="files-upload" className="file-container">
          <div id="files-label-upload-container" style={{maxWidth: "100%"}} className="upload-container">
            {props.current.state !== "review" ? <input type="file" id="fileupload" disabled={status} onChange={e => {if (!status) {setFiles(e.target.files); setSelectedFiles(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} /> : null}
            <label id="artwork-pic-label" for="fileupload"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="files-currently-selected-text" className="current-selection">
              <p className="selected-text">{selectedFiles}</p>
            </div>
          </div>
        </div>
        <p className="subheading-text">Image Texture File (Optional)</p>
        <p>Upload a jpeg or png (500 KB limit)</p>
        <div id="texture-upload" className="file-container">
          <div id="texture-label-upload-container" className="upload-container">
          {props.current.state !== "review" ? <input type="file" id="texture-pic" disabled={status} accept="image/jpeg, image/png" onChange={e => {if (!status) {setTexture(e.target.files); setSelectedTexture(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} required/> : null}
            <label id="artwork-pic-label" for="texture-pic"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="texture-currently-selected-text" className="current-selection">
              {(selectedTexture !== "" && props.current.baked.length > 2) || (texture !== null && texture.length > 0) ? <button className="small-x" disabled={status} type="button" onClick={()=> {if (!status) {removeItem(props.current.baked, texture, setTexture, setSelectedTexture, "baked");}}}>x</button> : null}
              <p className="selected-text">{selectedTexture}</p>
            </div>
          </div>
          <div id="texture-preview" className="icon-prev">
            <img className="icon-prev-image" src={textureUrl} alt="Select jpeg files to apply baked textures to your 3d model."/>
          </div>
        </div>
      </div>
      <div id="middle-panel" className ="columnSettings">
        <div className="unity-container">
          <Unity unityContent={unityContent} />
        </div>
      </div>
      <div id="right-panel" className="columnSettings-right">
        <p className="subheading-text">Audio File (Optional)</p>
        <p>Upload an mp3 file (1 MB limit). Note: audio auto-plays and loops in-app.</p>
        <div id="audio-upload" className="file-container">
          <div id="audio-label-upload-container" style={{maxWidth: "100%", height: "5vw", display: 'inline-grid'}} className="upload-container">
            <div id="audio-top-container" style={{display:'flex', margin:'auto', marginLeft:'0'}}>
              {props.current.state !== "review" ? <input type="file" id="audioupload" disabled={status} accept="audio/mp3" onChange={e => {if (!status) {setAudio(e.target.files); setSelectedAudio(`Selected ${e.target.files[0].name}`); if (displaySave === false){setDisplaySave(true);}}}} /> : null}
              <label style={{marginTop: '0.8vw'}} id="artwork-pic-label" for="audioupload"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
              <p style={{marginTop:'1.25vw'}} className="instruction">Click to select</p>
              <div id="audio-currently-selected-text" className="current-selection">
                {selectedAudio !== "" && (props.current.audioabout.length > 2 || audio.length > 0) ? <button className="small-x" style={{marginRight:'0.5vw'}} disabled={status} type="button" onClick={()=> {if (!status) {removeItem(props.current.audioabout, audio, setAudio, setSelectedAudio, "audio");}}}>x</button> : null}
                <p className="currently-selected-text">{selectedAudio}</p>
              </div>
            </div>
              <audio id="audio-playback" controls src={audioUrl}> Your browser does not support the<code>audio</code> element.</audio>
          </div>
        </div>
        <p className="subheading-text">Positioning Information</p>
        <p style={{fontWeight: '600'}}>Size</p>
        <input id="size" className="size-rot-input" disabled={status} defaultValue={props.current.size} type="text" onChange={e => {if (!status) {props.current.size = e.target.value; if(e.target.value.trim() !== ''){changeSize(e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        <p style={{fontWeight: '600'}}>Position</p>
        <div>
          <label for="xval" className="size-rot-label">X</label>
          <input id="xval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.xVal} onChange={e => {if (!status) {props.current.xVal = e.target.value; if(e.target.value.trim() !== ''){changePos(e.target.value, props.current.yVal, props.current.zVal);} if (displaySave === false){setDisplaySave(true);}}}} />
          <label for="yval" className="size-rot-label">Y</label>
          <input id="yval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.yVal} onChange={e => {if (!status) {props.current.yVal = e.target.value; if(e.target.value.trim() !== ''){changePos(props.current.xVal, e.target.value, props.current.zVal);} if (displaySave === false){setDisplaySave(true);}}}} /> 
          <label for="zval" className="size-rot-label">Z</label>
          <input id="zval" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.zVal} onChange={e => {if (!status) {props.current.zVal = e.target.value; if(e.target.value.trim() !== ''){changePos(props.current.xVal, props.current.yVal, e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        </div>
        <p style={{fontWeight: '600'}}>Rotation</p>
        <div>
          <label for="xrot" className="size-rot-label">X</label>
          <input id="xrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.xRot} onChange={e => {if (!status) {props.current.xRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(e.target.value, props.current.yRot, props.current.zRot);} if (displaySave === false){setDisplaySave(true);}}}} />
          <label for="yrot" className="size-rot-label">Y</label>
          <input id="yrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.yRot} onChange={e => {if (!status) {props.current.yRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(props.current.xRot, e.target.value, props.current.zRot);} if (displaySave === false){setDisplaySave(true);}}}} /> 
          <label for="zrot" className="size-rot-label">Z</label>
          <input id="zrot" className="size-rot-input" type="text" disabled={status} defaultValue={props.current.zRot} onChange={e => {if (!status) {props.current.zRot = e.target.value; if(e.target.value.trim() !== ''){changeRot(props.current.xRot, props.current.yRot, e.target.value);} if (displaySave === false){setDisplaySave(true);}}}} />
        </div>
        <p className="subheading-text">Tags</p>
        <div>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={model3d_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setmodel3d_tag(true);} else {setmodel3d_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb1"/><label className="tags-button" for="cb1">3D Model</label>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={animation_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setanimation_tag(true);} else {setanimation_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb2"/><label className="tags-button" for="cb2">Animation</label>
          <input type="checkbox" className="hide-checkbox" disabled={status} checked={sound_tag} onChange={(e)=>{if (!status) {if(e.target.checked === true){setsound_tag(true);} else {setsound_tag(false);} if (displaySave === false){setDisplaySave(true);}}}} id="cb3"/><label className="tags-button" for="cb3">Sound</label>
        </div>
        <p className="subheading-text">License (Required)</p>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb1" disabled={status} checked={allrights_tag} onChange={e=>{if (!status) {setallrights_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>All Rights Reserved:</span> you are the full copyright holder</p>   
        </div>
        <div id="license-text-container2" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb2" disabled={status} checked={universal_tag} onChange={e=>{if (!status) {setuniversal_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Universal Public Domain (CC0):</span> You waive all rights to others can copy, modify, distribute the work</p>
        </div>
        <div id="license-text-container3" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb3" disabled={status} checked={attributionshare_tag} onChange={e=>{if (!status) {setattributionshare_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-ShareAlike (CC BY-SA):</span> Others can copy, modify, and redistribute work but must provide appropriate credit to the original creator; if work is modified the work must be distributed under the same CC BY-SA licence</p>        
        </div>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" className="supersize-checkbox" id="scb4" disabled={status} checked={attributionnoncom_tag} onChange={e=>{if (!status) {setattributionnoncom_tag(e.target.checked); if (displaySave === false){setDisplaySave(true);}}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-NonCommerical-ShareAlike (CC BY-NC-SA):</span> Others can copy, modify, and redistribute work but not for commercial purposes; they must give appropriate credit to the original creator and the work must be distributed under the same CC BY-NC-SA license</p>
        </div>
        <div>
          {props.current.state !== "review" && displaySave === true ? <button className="small-button" type="button" disabled={status} onClick={() => {if(!status){onClickHandler();}}}>Save</button> : null}
          {props.current.state === "review" ? <button style={{float: 'left'}} className="small-button" type="button" onClick={() => {decision("true");}}>Approve</button> : null}
          {props.current.state === "review" ? <button className="small-button" type="button" onClick={() => {decision("ignore");}}>Reject</button> : null}
        </div>
      </div>
    </div>
 */