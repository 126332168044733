import React from 'react';  
import './EditPanel.css';  

const Panel = props => {
  return (
    <div className="profilepanel">
      {props.content}
    </div>
  );
};
 
export default Panel;