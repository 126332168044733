import React, { useContext, useState, useEffect, useRef } from 'react';
import './Editor.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Unity, { UnityContext } from 'react-unity-webgl';

import {usn, profile} from '../Helper/Context';

/*const buildjson = process.env.PUBLIC_URL + "/videobuild/platform.json";
const buildloader = process.env.PUBLIC_URL + "/videobuild/UnityLoader.js";
const unityContent = new UnityContent(
  buildjson,
  buildloader, {
    modules: {
      CachedXMLHttpRequestDisable: true
    }
  }
);*/
const unityContent = new UnityContext({
  loaderUrl: "build/platform.loader.js",
  dataUrl: "build/platform.data",
  frameworkUrl: "build/platform.framework.js",
  codeUrl: "build/platform.wasm",
})

function VideoEditor() {
  const{user} = useContext(usn);
  const {prof} = useContext(profile);
  const{mode} = useContext(usn);
  const videoProps = useRef({
    state: "new",
    id: "",
    title: "",
    greenscreen_status: "",
    redValue: "",
    greenValue: "",
    blueValue: "",
    profileicon: "",
    videodesc: "",
    orientation: "portrait",
    loaded: 0,
    toastContainer: null,
    videofilename: "",
  });

  const [videoTitle, setVideoTitle] = useState("Type something...");
  const [videoDesc, setVideoDesc] = useState("Type something...");
  const [portrait, setPortrait] = useState(true);
  useEffect(() => {
    if (portrait === true){
      videoProps.current.orientation = "portrait";
      setLandscape(false);
    }
    if (portrait === false && landscape === false){
      setPortrait(true);
    }
  },[portrait]);

  const [landscape, setLandscape] = useState(false);
  useEffect(() => {
    if (landscape === true){
      videoProps.current.orientation = "landscape";
      setPortrait(false);
    }
    if (portrait === false && landscape === false){
      setPortrait(true);
    }
  },[landscape]);

  const [videoFile, setVideoFile] = useState(null);
  useEffect(() => {
    if (videoFile !== null && videoFile.length > 0) {
      setSelectedVideo(`Selected ${videoFile[0].name}`);
    } else {
      setSelectedVideo("");
      setVideoFile(null);
    }
  },[videoFile]);
  const [selectedVideo, setSelectedVideo] = useState("");
 
  const [videoIcon, setVideoIcon] = useState(null);
  useEffect(() => {
    if (videoIcon !== null && videoIcon.length > 0) {
      setSelectedIcon(`Selected ${videoIcon[0].name}`);
    } else {
      setSelectedIcon("");
      setVideoIcon(null);
    }
  },[videoIcon]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [iconLoc, setIconLoc] = useState(process.env.PUBLIC_URL + '/img_prv.jpg');
  const [gsState, setGsState] = useState(false);
  const [threshold, setTreshold] = useState(0.0);
  const [uploadStatus, setUploadStatus] = useState(false);
  useEffect(() => {
    if (uploadStatus === true) {
      videoProps.current.toastContainer = toast.info("Loading...", {autoClose: false});
    }
    if(uploadStatus === false){
      toast.dismiss(videoProps.current.toastContainer);
    }
  }, [uploadStatus]);
  const [displayButton, setDisplayButton] = useState(false);
  const [video_tag, setvideo_tag] = useState(false);
  const [videoanimation_tag, setvideoanimation_tag] = useState(false);
  const [videosound_tag, setvideosound_tag] = useState(false);

  const [allrights_videotag, setallrights_videotag] = useState(false);
  useEffect(() => {
    if (allrights_videotag === true){
      setuniversal_videotag(false);
      setattributionshare_videotag(false);
      setattributionnoncom_videotag(false);
    }
  }, [allrights_videotag]);
  const [universal_videotag, setuniversal_videotag] = useState(false);
  useEffect(() => {
    if (universal_videotag === true){
      setallrights_videotag(false);
      setattributionshare_videotag(false);
      setattributionnoncom_videotag(false);
    }
  }, [universal_videotag]);
  const [attributionshare_videotag, setattributionshare_videotag] = useState(false);
  useEffect(() => {
    if (attributionshare_videotag === true){
      setallrights_videotag(false);
      setuniversal_videotag(false);
      setattributionnoncom_videotag(false);
    }
  }, [attributionshare_videotag]);
  const [attributionnoncom_videotag, setattributionnoncom_videotag] = useState(false);
  useEffect(() => {
    if (attributionnoncom_videotag === true){
      setallrights_videotag(false);
      setuniversal_videotag(false);
      setattributionshare_videotag(false);
    }
  }, [attributionnoncom_videotag]);

  const [hexColor, setHexColor] = useState("");
  useEffect(() => {
    if (hexColor !== null && hexColor.length > 2){
      videoProps.current.redValue = parseInt(hexColor.substr(1,2), 16);
      videoProps.current.greenValue = parseInt(hexColor.substr(3,2), 16);
      videoProps.current.blueValue = parseInt(hexColor.substr(5,2), 16);
      unityContent.send("scriptManager", "changeColor", `${videoProps.current.redValue}\t${videoProps.current.greenValue}\t${videoProps.current.blueValue}`);
    }
  });

  const [playToggle, setPlayToggle] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if(history.location.state !== "new"){
      videoProps.current.id = history.location.id;
      videoProps.current.state = "edit";
      videoProps.current.title = history.location.name;
      videoProps.current.greenscreen_status = history.location.modifications;
      if (history.location.modifyingitems.length > 2){
        setGsState(true);
        let arr = history.location.modifyingitems.split('\t');
        //var hexIs = "#" + ((1 << 24) + (arr[0] << 16) + (arr[1] << 8) + arr[2]).toString(16).slice(1);
        var hexIs = "#";
        arr.filter((item, index) => index < 3).map(x => parseInt(x).toString(16).length === 1 ?  hexIs += "0" + parseInt(x).toString(16) : hexIs += parseInt(x).toString(16));
        videoProps.current.redValue = arr[0];
        videoProps.current.greenValue = arr[1];
        videoProps.current.blueValue = arr[2];
        setTreshold(parseFloat(arr[3]));
        console.log(hexIs);
        setHexColor(hexIs);
      }
      let tagDBVals = (history.location.tags).split('\t');
      if (tagDBVals.indexOf('Video') !== -1){
        setvideo_tag(true);
      }
      if (tagDBVals.indexOf('Animated') !== -1){
        setvideoanimation_tag(true);
      }
      if (tagDBVals.indexOf('Sound') !== -1){
        setvideosound_tag(true);
      }
      if(history.location.license === "allrights"){
        setallrights_videotag(true);
      }
      if(history.location.license === "universalpub"){
        setuniversal_videotag(true);
      }
      if(history.location.license === "attributionshare"){
        setattributionshare_videotag(true);
      }
      if(history.location.license === "attributionnoncom"){
        setattributionnoncom_videotag(true);
      }
      videoProps.current.profileicon = history.location.iconstate;
      videoProps.current.videodesc = history.location.descstate;
      //
      videoProps.current.orientation = history.location.medium;
      videoProps.current.videofilename = history.location.fnstate;
      (history.location.medium === "portrait") ? setPortrait(true) : setPortrait(false); 
      (history.location.medium === "portrait") ? setLandscape(false) : setLandscape(true); 
      setVideoTitle(history.location.name);
      setVideoDesc(history.location.descstate);
      setIconLoc(`https://sharedspaces-dev.usask.ca/app2/serve/artworkspic/${history.location.iconstate}`);
      setSelectedIcon(`Selected ${history.location.iconstate}`);
      setSelectedVideo(`Selected ${history.location.fnstate}`);
    }
    unityContent.on("loaded", () => {
      if(history.location.state === "new"){
        unityContent.send("scriptManager", "reorient", "portrait");
        //setTimeout(() => {  destroyObj(); }, 700);
        //set url to empty
      } else if (history.location.state === "edit" || history.location.state === "review") {
        let rgbValues = history.location.modifyingitems.split('\t');
        unityContent.send("scriptManager", "setUrl", history.location.fnstate);
        unityContent.send("scriptManager", "reorient", history.location.medium);
        if (history.location.modifications === "shader" && history.location.modifyingitems.length > 2){
          unityContent.send("scriptManager", "changeSensitivity", rgbValues[3]);
          unityContent.send("scriptManager", "changeColor", `${rgbValues[0]}\t${rgbValues[1]}\t${rgbValues[2]}`);
        }
      }
    });
  }, [history]);

  if(!prof) {
    history.push('/profile');
  }

  const uploadCheck = async() => {
    if (history.location.state === "new"){
      let items = [videoFile, videoIcon, videoProps.current.title, videoProps.current.videodesc];
      let empty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
      if(!items.every(empty)){
        toast.warn('Please ensure the name and description fields are completed as well as video files and icon picture selected.');
        return
      }
    } else {
      let items = [videoProps.current.title, videoProps.current.videodesc];
      let notempty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
      if(!items.every(notempty)){
        toast.warn('Please ensure the name and description fields are completed.');
        return
      }
    }
    try {
      let hexRes = await checkFiles(videoFile, ['00020', '00018'], 10000000);
      //let sizeRes = await checkSize();
      let lengthRes = await checkLength();
      let picCheck = await checkFiles(videoIcon, ['FFD8FFE0', '89504E47'], 110000);
      if (hexRes !== true){
        toast.warn("Unsupported file format. Pleasre ensure the video is in mp4 baseline profile and under 10mbs."); 
      }
      /*if (sizeRes !== true) {
        toast.warn("Please ensure the video is no larger than 10mb.");
      }*/
      if (lengthRes > 600) {
        toast.warn("Please ensure the video is no longer than 10 minutes.");
      }
      if (picCheck !== true) {
        toast.warn("Please ensure the artwork icon is no larger than 100KB and is in png or jpeg format.");
      }
      if (hexRes === true && lengthRes !== false && picCheck === true){
        doUpload();
      } else {
        return
      }
    } catch (e) {
      toast.warn(e);
    }
  }

  const doUpload = async() => {
    setUploadStatus(true);
    const multiForm = new FormData();
    let updatetype = "";
    let tagStr = "";
    let rightsTag = "";
    if (videoProps.current.state === "new"){
      updatetype += "new";
    } else {
      multiForm.append('id', videoProps.current.id);
    }
    let tagStateArr = [video_tag, videoanimation_tag, videosound_tag];
    let tagValArr = ['Video', 'Animated', 'Sound'];
    for(var x = 0; x < tagStateArr.length; x++){
      if (tagStateArr[x] !== false){
        tagStr = tagStr + tagValArr[x] + '\t';
      }
    }
    if (tagStr.length > 0){
      tagStr = tagStr.substring(0, tagStr.length - 1);
    }
    let rightstagArr = [allrights_videotag, universal_videotag, attributionshare_videotag, attributionnoncom_videotag];
    let rightstagValue = ['allrights', 'universalpub', 'attributionshare', 'attributionnoncom'];
    for(var i = 0; i < rightstagArr.length; i++){
      if (rightstagArr[i] !== false){
        rightsTag = rightstagValue[i];
      }
    }
    multiForm.append('isVideo', 'true');
    multiForm.append('itemname', videoProps.current.title);
    multiForm.append('uploader', user);
    if (videoFile !== null && videoFile.length > 0){
      multiForm.append('videofn', videoFile[0]);
      updatetype += "vidfn";
    }
    if (videoIcon !== null && videoIcon.length > 0) {
      multiForm.append('iconfn', videoIcon[0]);
      updatetype = updatetype + "iconpic";
    }
    multiForm.append('artist', prof[0].artistName);
    multiForm.append('itemdesc', videoProps.current.videodesc);
    //
    if (gsState === true){
      multiForm.append('modifications', 'shader');
      var greenscreenvalue = `${videoProps.current.redValue}\t${videoProps.current.greenValue}\t${videoProps.current.blueValue}\t${threshold}\t0`;
      multiForm.append('modifyingitems', greenscreenvalue);
    } else {
      multiForm.append('modifications', '');
      multiForm.append('modifyingitems', '');
    }
    multiForm.append('tags', tagStr);
    multiForm.append('license', rightsTag);
    multiForm.append('orientation', videoProps.current.orientation);
    multiForm.append('updatetype', updatetype);
    multiForm.append('size', '0');
    multiForm.append('xVal', '0');
    multiForm.append('yVal', '0');
    multiForm.append('zVal', '0');
    multiForm.append('xRot', '0');
    multiForm.append('yRot', '0');
    multiForm.append('zRot', '0');
    await axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/asyncupdating`, 
      headers: {
          'Content-Type': 'multipart/form-data',
      },
      data: multiForm,
      onUploadProgress: ProgressEvent => { 
        videoProps.current.loaded = (ProgressEvent.loaded / ProgressEvent.total*100);
      }
    }).then(function (response) {
      if (videoFile){
        unityContent.send("scriptManager", "setUrl", `${(response.data.append + videoFile[0].name).replace(/\s/g, "")}`);
        videoProps.current.videofilename = `${(response.data.append + videoFile[0].name).replace(/\s/g, "")}`;
      }
      if (videoIcon){
        setIconLoc(`https://sharedspaces-dev.usask.ca/app2/serve/artworkspic/${(response.data.append + videoIcon[0].name).replace(/\s/g, "")}`);
        videoProps.current.profileicon = `${(response.data.append + videoIcon[0].name).replace(/\s/g, "")}`;
      }
      toast.success("Upload successful.");
      //call unity here for loading with green screen values
      if (videoProps.current.state === "new"){
        videoProps.current.id = response.data.id;
        videoProps.current.state = "edit";
      }
      setUploadStatus(false);
      setVideoFile(null);
      setVideoIcon(null);
      setDisplayButton(false);
    }).catch(function() {
      toast.warn('Upload failed.');
      setUploadStatus(false);
    });
  }

  const decision = (verdict) => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/itemreview`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        itemid: videoProps.current.id,
        item: videoProps.current.title,
        account: history.location.uploadername,
        columnvalue: verdict
      }
    }).then(() => {
      toast.success("Saved.");
    }).catch(function (error) {
      toast.warn('Error connecting to the server.');
    });
  }

  const checkHex = () => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      let blob = videoFile[0].slice(0,4);
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        const uint = new Uint8Array(reader.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        })
        let hex = bytes.join('').toUpperCase();
        if (hex !== '00020' && hex !== '00018') {
          resolve(false);
        } else {
          resolve(true);
        }
      }
      reader.onerror = (error) => toast.warn("Failed to read fbx file.");
    })
  }

  const checkSize = () => {
    return new Promise((resolve) => {
      if (videoFile[0].size < 10000000) {
        console.log("size is fine");
        resolve(true);
      } else {
        console.log(videoFile[0].size);
        resolve(false);
      }
    })
  }

  const checkLength = () => {
    return new Promise((resolve) => {
      if (videoFile === null || videoFile.length < 1){
        resolve(1);
      } else {
        console.log(videoFile[0]);
        let audioReader = new FileReader();
        audioReader.onload = () => {
          let media = new Audio(audioReader.result);
          media.onloadedmetadata = () => resolve(media.duration);
        }
        audioReader.readAsDataURL(videoFile[0]);
        audioReader.onerror = (error) => {resolve(false); toast.warn("Failed to read video file.");}
      } 
    });
  }

  const checkFiles = (varFile, varHex, varLimit) => {
    return new Promise((resolve) => {
      if (varFile === null || varFile.length < 1){
        resolve(true);
      } else {
        let reader = new FileReader();
        let blob = varFile[0].slice(0,4);
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
          const uint = new Uint8Array(reader.result);
          let bytes = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          })
          let hex = bytes.join('').toUpperCase();
          let matchvalue = 0;
          console.log(hex);
          for(var v = 0; v < varHex.length; v++){
            if (hex === varHex[v]){
              matchvalue += 1;
            }
          }
          if (matchvalue < 1 || varFile[0].size > varLimit) {
            resolve(false);
          } else {
            resolve(true);
          }
        }
      } 
    })
  }

  const old_checkLength = () => {
    return new Promise((resolve) => {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        if (video.duration > 600) {
          resolve(false);
        } else {
          console.log("length is fine");
          resolve(true);
        }
      }
      video.src = URL.createObjectURL(videoFile[0]);
    })
  }

  return (
    <div>
      <ToastContainer/>
      <div id="video-left-panel" className="appleftPanel">
        <div>
          <button className="back-btn" onClick={() => history.goBack()}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
          <p className="headline-text">Shared Spaces Video Uploader</p>
        </div>
        <div>
          <p className="subheading-text">Artwork Name (Required)</p>
          <input type="text" disabled={uploadStatus} value={videoTitle} onChange={e => {videoProps.current.title = e.target.value; setVideoTitle(e.target.value); if(!displayButton){setDisplayButton(true);}}} required/>
        </div>
        <div>
          <p className="subheading-text">Artwork Description (Required)</p>
          <textarea className="longform-input" disabled={uploadStatus} value={videoDesc} onChange={e => {videoProps.current.videodesc = e.target.value; setVideoDesc(e.target.value); if(!displayButton){setDisplayButton(true);}}} required/>
        </div>
 
        <p className="subheading-text">Artwork Profile Icon (Required)</p>
        <p>Upload a jpeg (100 KB limit)</p>
        <div id="video-iconpic-upload" className="file-container">
          <div id="video-iconpic-label-upload-container" className="upload-container">
            <input type="file" disabled={uploadStatus} id="video-pic" accept="image/jpeg, image/png" onChange={e => {setVideoIcon(e.target.files); if(!displayButton){setDisplayButton(true);}}} required/>
            <label id="video-pic-label" for="video-pic"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
            <p className="instruction">Click to select</p>
            <div id="video-iconpic-currently-selected-text" className="current-selection">
              <p className="selected-text">{selectedIcon}</p>
            </div>
          </div>
          <div id="profile-pic-preview" className="icon-prev">
            <img className="icon-prev-image" src={iconLoc} alt="Select audio files to narrate this piece."/>
          </div>
        </div>
        <p className="subheading-text">License</p>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" disabled={uploadStatus} className="supersize-checkbox" id="scb1" checked={allrights_videotag} onChange={e=>{setallrights_videotag(e.target.checked); if(!displayButton){setDisplayButton(true);}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>All Rights Reserved:</span> you are the full copyright holder</p>   
        </div>
        <div id="license-text-container2" style={{width: "100%"}}>
          <input type="checkbox" disabled={uploadStatus} className="supersize-checkbox" id="scb2" checked={universal_videotag} onChange={e=>{setuniversal_videotag(e.target.checked); if(!displayButton){setDisplayButton(true);}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Universal Public Domain (CC0):</span> You waive all rights to others can copy, modify, distribute the work</p>
        </div>
        <div id="license-text-container3" style={{width: "100%"}}>
          <input type="checkbox" disabled={uploadStatus} className="supersize-checkbox" id="scb3" checked={attributionshare_videotag} onChange={e=>{setattributionshare_videotag(e.target.checked); if(!displayButton){setDisplayButton(true);}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-ShareAlike (CC BY-SA):</span> Others can copy, modify, and redistribute work but must provide appropriate credit to the original creator; if work is modified the work must be distributed under the same CC BY-SA licence</p>        
        </div>
        <div id="license-text-container4" style={{width: "100%"}}>
          <input type="checkbox" disabled={uploadStatus} className="supersize-checkbox" id="scb4" checked={attributionnoncom_videotag} onChange={e=>{setattributionnoncom_videotag(e.target.checked); if(!displayButton){setDisplayButton(true);}}}/>
          <p className="license-text"><span style={{fontWeight: "bold"}}>Attribution-NonCommerical-ShareAlike (CC BY-NC-SA):</span> Others can copy, modify, and redistribute work but not for commercial purposes; they must give appropriate credit to the original creator and the work must be distributed under the same CC BY-NC-SA license</p>
        </div>
      </div>
      <div id="middle-panel" className ="appmiddlePanel">
        <div className="unity-container">
          <Unity unityContext={unityContent} />
        </div>
      </div>
      <div id="video-right-panel" className="apprightPanel">
        <p className="subheading-text">Video File (Required)</p>
          <p>Upload a mp4 file (8 MB limit)</p>
          <div id="video-upload" className="file-container">
            <div id="video-label-upload-container" style={{maxWidth: "100%"}} className="upload-container">
              <input type="file" disabled={uploadStatus} id="videoupload" onChange={e => {setVideoFile(e.target.files); if(!displayButton){setDisplayButton(true);}}} />
              <label id="video-upload-label" for="videoupload"><img style={{width: "2vw"}} src={process.env.PUBLIC_URL + '/folder.png'} alt="Profile icon preview."/></label>
              <p className="instruction">Click to select</p>
              <div id="files-currently-selected-text" className="current-selection">
                <p className="selected-text">{selectedVideo}</p>
              </div>
            </div>
          </div>
        <p className="subheading-text">Video File Keying Selection</p>
        <p>Select a colour to make transparent</p>
        <div id="video-alpha" className="file-container">
          <div id="video-alpha-label-container" style={{maxWidth: "100%", height: "5vw", display: 'inline-grid'}} className="upload-container">
            <div id="video-alpha-top-container" style={{display:'flex', margin:'auto', marginLeft:'0', marginTop:'1vw', width:'100%'}}>
              <input type="checkbox" disabled={uploadStatus} id="colortoggle" style={{display:'flex', margin:'auto', marginLeft:'1vw', marginRight:'0.1vw'}} className="supersize-checkbox" checked={gsState} onChange={e=>{setGsState(e.target.checked);}}/>
              <p style={{display:'flex', margin:'auto', marginLeft:'0.5vw'}} className="instruction">Check to enable</p>
              {gsState ? <input type="color" disabled={uploadStatus} id="color-picker-id" className="color-picker" value={hexColor} onChange={e=> {setHexColor(e.target.value); if(!displayButton){setDisplayButton(true);} console.log(e.target.value);}}/> : null}
            </div>
            <div style={{display:'flex', margin:'auto', marginLeft:'0', marginBottom:'1vw', width:'100%'}}>
              <p style={{display:'flex', margin:'auto', marginLeft:'1vw'}}>Threshold</p>
              <input type="range" disabled={uploadStatus} id="threshold-value" style={{display:'flex', margin:'auto'}} name="threshold-value" min="0" max="3" value={threshold} onChange={e=>{setTreshold(e.target.value); unityContent.send("scriptManager", "changeSensitivity", e.target.value); if(!displayButton){setDisplayButton(true);}}} step="0.1"></input>
              <p style={{display:'flex', margin:'auto', width:'2vw', marginRight:'1vw'}}>{threshold}</p>
            </div>
          </div>
        </div>
        <p className="subheading-text">Orientation</p>
        <div className="video-props-input">
          <input type="checkbox" disabled={uploadStatus} id="portrait" className="supersize-checkbox" checked={portrait} onChange={e=>{setPortrait(e.target.checked); unityContent.send("scriptManager", "reorient", "portrait"); if(!displayButton){setDisplayButton(true);}}}/>
          <label for="portrait">Portrait</label>
        </div>
        <div className="video-props-input">
          <input type="checkbox" disabled={uploadStatus} id="landscape" className="supersize-checkbox" checked={landscape} onChange={e=>{setLandscape(e.target.checked); unityContent.send("scriptManager", "reorient", "landscape"); if(!displayButton){setDisplayButton(true);}}}/>
          <label for="landscape">Landscape</label>
        </div>
        <p className="subheading-text">Tags</p>
        <div>
          <input type="checkbox" disabled={uploadStatus} checked={video_tag} className="hide-checkbox"  onChange={(e)=>{if(e.target.checked === true){setvideo_tag(true);} else {setvideo_tag(false);} if(!displayButton){setDisplayButton(true);}}} id="vcb1"/><label className="tags-button" for="vcb1">Video</label>
          <input type="checkbox" disabled={uploadStatus} checked={videoanimation_tag} className="hide-checkbox"  onChange={(e)=>{if(e.target.checked === true){setvideoanimation_tag(true);} else {setvideoanimation_tag(false);} if(!displayButton){setDisplayButton(true);}}} id="vcb2"/><label className="tags-button" for="vcb2">Animation</label>
          <input type="checkbox" disabled={uploadStatus} checked={videosound_tag} className="hide-checkbox"  onChange={(e)=>{if(e.target.checked === true){setvideosound_tag(true);} else {setvideosound_tag(false);} if(!displayButton){setDisplayButton(true);}}} id="vcb3"/><label className="tags-button" for="vcb3">Sound</label>
        </div>
        {videoProps.current.videofilename.length > 2 ? <p className="subheading-text">Media Controls</p> : null}
        <div>
          {videoProps.current.videofilename.length > 2 ? <button className="media-controls" disabled={uploadStatus} onClick={()=>{setPlayToggle(!playToggle); unityContent.send("scriptManager", "pauseplay");}}><img src={playToggle === true ? process.env.PUBLIC_URL + '/playButton.png' : process.env.PUBLIC_URL + '/pauseButton.png'} alt="Play/Pause" width='32px'/></button> : null}
          {videoProps.current.videofilename.length > 2 ? <button className="media-controls" disabled={uploadStatus} onClick={()=>{unityContent.send("scriptManager", "muteaudio");}}><img src={process.env.PUBLIC_URL + '/audio.png'} alt="Mute/Unmute" width='30px'/></button> : null}
        </div>
        <div>
          {history.location.state !== "review" && displayButton === true ? <button disabled={uploadStatus} className="small-button" onClick={()=> {uploadCheck();}}>Save</button> : null}
        </div>
        {history.location.state === "review" ? <button className="small-button" style={{float: "left", marginTop:"2vw"}} type="button" onClick={() => {decision("true");}}>Approve</button> : null}
        {history.location.state === "review" ? <button className="small-button" style={{marginTop:"2vw"}} type="button" onClick={() => {decision("ignore");}}>Reject</button> : null}
      </div>
    </div>
  );
}
export default VideoEditor;
//<input type="file" disabled={uploadStatus} id="videoupload" onChange={e => {setVideoFile(e.target.files); if(!displayButton){setDisplayButton(true);}}} />