import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Editor from './components/Editor';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Account from './components/Account';
import Review from './components/Review';
import Testpage from './components/Testpage';
import Spaces from './components/Spaces';
import Exhibitions from './components/Exhibitions';
import VideoEditor from './components/VideoEditor';
import TermsandPrivacy from './components/TermsandPrivacy';
import Jskeying_VideoEditor from './components/jskeying_VideoEditor';
import {isauth, usn, profile} from './Helper/Context';

function App (){
  const [user, setUser] = useState();
  const [au, setau] = useState();
  const [prof, setprof] = useState();
  const mode = "app2";
  //<Route path="/" exact component={() => <Login thispass={setPat}/>} />
  return (
    <isauth.Provider value={{au, setau}}>
      <usn.Provider value={{user, setUser, mode}}>
        <profile.Provider value={{prof, setprof}}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login/:handoff" exact component={Login} />
              <Route path="/privacypolicy" exact component={TermsandPrivacy} />
              <Route path="/termsandcondition" exact component={TermsandPrivacy} />
              {
                user ?
                <>
              <Route path="/testpage" exact component={Testpage} />
              <Route path="/jskey" exact component={Jskeying_VideoEditor} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/editor" component={Editor} />
              <Route path="/videoeditor" exact component={VideoEditor} />
              <Route path="/profile" exact component={Account} />
              <Route path="/review">{au ? <Review /> : <Redirect to="/dashboard"/>}</Route>
              <Route path="/spaces">{au ? <Spaces /> : <Redirect to="/dashboard"/>}</Route>
              <Route path="/exhibitions">{au ? <Exhibitions /> : <Redirect to="/dashboard"/>}</Route>
              </> : <Redirect to="/" />
              }
            </Switch>
            <div>

            </div>
          </BrowserRouter>
        </profile.Provider>
      </usn.Provider>
    </isauth.Provider>
  );
}

export default App;
/*
<Route path="/" exact component={Login} />
              {
                user ?
                <>
                <Route path="/profile" component={Account} />
                {
                  prof ?
                  <>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/editor" component={Editor} />
                  </> : <Redirect to="/profile" />
                }
                </> : <Redirect to="/" />
              }
*/