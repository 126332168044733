import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGoogleLogout } from 'react-google-login';

import {usn, profile} from '../Helper/Context';
import Menu from './Menu';
import EditPanel from './EditPanel';
import Popup from './Popup';

function Dashboard({username}) {
  const{user} = useContext(usn);
  const { mode } = useContext(usn);
  const{prof, setprof} = useContext(profile);
  useEffect(() => {
    if(prof){
        setartistname(prof[0].artistName);
        setlocation(prof[0].locationbased);
        if (prof[0].artistPic.length > 0 && typeof prof[0].artistPic !== 'undefined'){
          var profilepicadd = "https://sharedspaces-dev.usask.ca/app2/serve/artistspic/" + prof[0].artistPic;
          setprofilepicname(profilepicadd);
        }
        setuploadtext("Click image to change.");
        setbio(prof[0].bio);
    }
  }, [prof]);
  const [profilepicname, setprofilepicname] = useState(process.env.PUBLIC_URL + '/select.png');
  const [artistname, setartistname] = useState();
  const [bio, setbio] = useState();
  const [location, setlocation] = useState();
  const [uploadtext, setuploadtext] = useState();
  const [profilepic, setprofilepic] = useState(null);
  const [warning, setwarning] = useState(false);

  const onLogoutSuccess = () => {
    history.push('/');
  }
  const { signOut } = useGoogleLogout({
    onLogoutSuccess
  });

  useEffect(() => {
    async function checkIcon(){
      if(profilepic && profilepic.length > 0){
        let check_icon = await verifyProfileImage(profilepic, ['FFD8FFE0', '89504E47', 'FFD8FFE2'], 11000);
        if (check_icon === true) {
          var updtmessage = "Selected " + profilepic[0].name;
          setuploadtext(updtmessage);
        } else {
          toast.warn("Please ensure the profile icon file is in jpeg/png format and is under 10kb.");
          setprofilepic(null);
        }
      } else {
        setuploadtext("Click image to change (10 KB limit).");
      }
    }
    checkIcon();
  }, [profilepic]);

  let history = useHistory();
  const [ismenu, setisMenu] = useState(false);

  /* Submits a form to the server to request a json of all the items uploaded by the username currently logged in.
   * Form sends the username to the route that handles requests for finding all assets associated with the username.
   */
  const getprofile = () => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/dbrequests`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        reqtype: "accountprofile",
        findthis: user
      }
    }).then(function(response){
      setprof(response.data);
      } 
    ).catch(function (error) {
      toast.warn('Server error.');
    });
  } 

  /* Submit action in profile edit page. If a previous profile already exists, the changes are saved and if not, it will call the method to create a new profile.
   */
  const checkProf = () => {
    if(prof){
      newsaveProfile();
    } else {
      createProfile();
    }
  }

  /*Verifies selected profile images are in the correct format (jpgs and pngs) and are within the size limit.*/
  const verifyProfileImage = (varFile, varHex, varLimit) => {
    return new Promise((resolve) => {
      if (varFile === null || varFile.length < 1){
        resolve(true);
      } else {
        let reader = new FileReader();
        let blob = varFile[0].slice(0,4);
        reader.readAsArrayBuffer(blob);
        reader.onloadend = () => {
          const uint = new Uint8Array(reader.result);
          let bytes = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          })
          let hex = bytes.join('').toUpperCase();
          let matchvalue = 0;
          for(var v = 0; v < varHex.length; v++){
            if (hex === varHex[v]){
              matchvalue += 1;
            }
          }
          if (matchvalue < 1 || varFile[0].size > varLimit) {
            console.log(varFile[0].size);
            resolve(false);
          } else {
            resolve(true);
          }
        }
      } 
    })
  }


  /* Submits a form to the server to create a new profile for the user using the values in the fields in the profile edit page.
   */
  const createProfile = async() => {
    const newprofile = new FormData();
    let arr = [artistname, location, bio, profilepic];
    let empty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
    if(!arr.every(empty)){
      toast.warn('Please ensure every input field is completed and an icon is selected.');
      return
    } else {
      newprofile.append('dblookup', 'artists');
      newprofile.append('artistName', artistname);
      newprofile.append('location', location);
      newprofile.append('bio', bio);
      newprofile.append('artistPic', profilepic[0].name);
      newprofile.append('artworks', '');
      newprofile.append('exhibitions', '');
      newprofile.append('galleries', '');
      newprofile.append('uploader', user);
      newprofile.append('artistfn', profilepic[0]);
      newprofile.append('updatetype', "newartist");
      await axios({
        method: 'post',
        url: `https://sharedspaces-dev.usask.ca/${mode}/new_dashmodify`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: newprofile
      }).then((res) => {
        var newprofpicadd = "https://sharedspaces-dev.usask.ca/app2/serve/artistspic/" + res.data.append + profilepic[0].name.replace(/\s/g, "");
        setprofilepicname(newprofpicadd);
        toast.success('Artist info saved.');
        getprofile();
        setprofilepic(null);
      }).catch(() => {
        toast.warn('Failed to create new profile.');
      });
    }
  }

  /* Submits a form to the server to save changes to the fields in the profile edit page. Grabs the current values in all the fields.
   */
  const newsaveProfile = async() => {
    const tosave = new FormData();
    if (profilepic && profilepic.length > 0){
      tosave.append('artistfn', profilepic[0]);
      tosave.append('artistPic', profilepic[0].name);
    }
    tosave.append('dblookup', 'artists');
    tosave.append('updatetype', 'artist');
    tosave.append('artistName', artistname);
    tosave.append('location', location);
    tosave.append('bio', bio);
    tosave.append('uploader', user);
    tosave.append('id', prof[0].id);
    await axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/new_dashmodify`, 
      headers: {
          'Content-Type': 'multipart/form-data',
        },
      data: tosave
    }).then(res => {
      toast.success('Artist info updated.');
      if(profilepic && profilepic.length > 0){
        var newprofpicadd = "https://sharedspaces-dev.usask.ca/app2/serve/artistspic/" + res.data.append + profilepic[0].name.replace(/\s/g, "");
        setprofilepicname(newprofpicadd);
      }
      getprofile();
      setprofilepic(null);
    })
    .catch(err => {
      toast.warn('Save failed.');
    });
  }

  /* Submits a form to the server to delete the user account. Form sends the username to the route that handles delete requests.
   */
  const deleteAccount = () => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/delacc`,
      headers: {'Content-Type': 'application/json'},
      data: {
        uploader: user
      } }).then(function (response) {
        toast.success('Account deleted.');
        signOut();
      })
      .catch(function (error) {
        toast.warn('Account deletion failed.');
      });
  }

  return (
      <div>
        {prof ? <button className="menu-btn" onClick={() => setisMenu(!ismenu)}><img src={process.env.PUBLIC_URL + '/menu.png'} alt="Menu" width='35px'/></button> : null}
        {ismenu &&  <Menu active={"profile"} menuis={ismenu} setMenuis={setisMenu} handleClose={() => setisMenu(!ismenu)} />}
        <ToastContainer />
        <div>
          {history.location.state === "newprofile" && prof ? <button className="back-btn" onClick={()=>{history.push('/dashboard');}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button> : null}
          {history.location.state !== "newprofile" && prof ? <button className="back-btn" onClick={()=>{history.goBack();}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button> : null}
        </div> 
        <h1 className="title">Profile</h1>
        {!prof ? <p style={{fontWeight: 'bold'}}>Create an artist profile to get started.</p> : null}
        <EditPanel content={<> 
          <div className="div-padding">
            <label className="bold-label" for="artist-name" >Artist Name</label>
            <input type="text" className="profiletextinput" defaultValue={artistname} id="artist-name" onChange={e => setartistname(e.target.value)} required/>
          </div>
          <div className="div-padding">
            <label className="bold-label" for="artist-location" >Artist Location</label>
            <input type="text" className="profiletextinput" defaultValue={location} id="artist-location" onChange={e => setlocation(e.target.value)} required/>
          </div>
          <div className="div-padding-pic">
            <p className="bold-label" >Artist Profile Pic</p>
            <div className="profilepiccontainer">
              <input type="file" id="artist-pic" accept="image/png, image/jpeg" onChange={e => setprofilepic(e.target.files)} required/>
              <label className="profilefileinput" for="artist-pic"><img src={profilepicname} alt="Profile icon preview." width="100px"/></label>
              <p className="inputtext">{uploadtext}</p>
            </div>
          </div>
          <div className="div-padding">
            <label className="bold-label" for="artist-bio" >Artist Bio</label>
            <textarea className="profilelonginput" style={{height: '100px'}} defaultValue={bio} id="artist-bio" form="artwork-info" onChange={e => setbio(e.target.value)} required/>
          </div>
        </>}/>
        <button className="btn-bottom" onClick={checkProf}>Save</button> 
        <button className="btn-bottom" style={{'float':'left'}} onClick={()=>{setwarning(!warning);}}>Delete Account</button> 
        {warning && <Popup  
            content={<>
                <h1 className="center-text">Warning</h1>
                <p>You are deleting your account. This action cannot be reversed. Click the button below to proceed.</p>
                <button className="btn-bottom" onClick={()=>{deleteAccount();}}>Delete Account</button>
              </>}
              handleClose={() => {setwarning(!warning);}}
            />}
      </div>
  );
}

export default Dashboard;

/*
const oldcreateProfile = async() => {
  const artistinfo = new FormData();
  artistinfo.append('artist', artistname);
  artistinfo.append('location', location);
  artistinfo.append('bio', bio);
  artistinfo.append('artistPic', profilepic[0].name);
  artistinfo.append('artworks', '');
  artistinfo.append('exhibitions', '');
  artistinfo.append('galleries', '');
  artistinfo.append('uploader', user);
  const p1 = axios.post("https://sharedspaces-dev.usask.ca/uploadconnect/artistcreate.php", artistinfo);
  const iconfile = new FormData();
  iconfile.append('file', profilepic[0]);
  const p2 = axios.post("https://sharedspaces-dev.usask.ca/app2/artistpicUp", iconfile);
  let promisestoconsume = [p1, p2];
  //await axios.post(
  //  "https://sharedspaces-dev.usask.ca/uploadconnect/artistcreate.php",
  //  artistinfo
  Promise.all(promisestoconsume).then(function (response) {
    //var msg = (response.request.response).trim();
    //if (msg === "0"){
    toast.success('Artist info saved.');
    getprofile();
    //} else {
    //    toast.warn('Failed to save artist info.');
  //}
  }).catch(err => {
    toast.warn('Save failed.');
  });
}
*/