import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {isauth, usn} from '../Helper/Context';
import Menu from './Menu';

function Review() {
  const history = useHistory();
  const{ au } = useContext(isauth);
  const { mode } = useContext(usn);
  const [ismenu, setisMenu] = useState(false);
  //const{prof, setprof} = useContext(profile);
  //console.log(user);
  //const[token, setToken] = useState({username});
  useEffect(() => {
    if(au === "admin"){
      getitems();
      //testingaddies();
      /*if(history.location.accstate === "admin"){
        getitems();
      } else {
        history.push({pathname: "/dashboard"});
      }*/
    }
  }, [au]);
  const[uploads, setUpload] = useState([]);
/*
  const testingaddies = async() => {
    const exampleForm = new FormData();
    exampleForm.append('formkey', "thisisastring");
    await axios.post("https://sharedspaces-dev.usask.ca/app2/testingforminfo", exampleForm
    ).then(function(response) {
      console.log(response);
    }).catch(function (error) {
      console.log(error);
    });
  }
*/
  /* Submits a form to the server to request all the items that are awaiting approval from mods. Data is returned in JSON form.
   */
  const getitems = async() => {
    await axios({
      method: 'post',
      url:`https://sharedspaces-dev.usask.ca/${mode}/dbrequests`,
      headers: {'Content-Type': 'application/json'},
      data: { reqtype: "approval"}
    }).then(function(response){
        setUpload(response.data);
    }).catch(function (error) {
        toast.warn('Error connecting to the server.');
    });
  }

  /* Generates the headers in the table that displays all the artworks awaiting approval from mods.
   */
  const renderHeader = () => {
    let headerElement = ['Item Name', 'Artist', 'Created', 'Modified', 'View', 'Approve', 'Reject']

    return headerElement.map((key, index) => {
        return <th key={index}>{key}</th>
    })
  }

  /* Submits a form to the server with the approval or rejection decision. 
   * param string id: id of the artwork being reviewed.
   * param string itemname: name of the artwork being reviewed.
   * param string uploader: username of the artwork's uploader.
   * param string verdict: the mod's decision.
   */
  const saveDecision = (id, itemname, uploader, verdict) => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/itemreview`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        itemid: id,
        item: itemname,
        account: uploader,
        columnvalue: verdict
      }
    }).then(() => {
      const del = uploads.filter(upload => itemname !== upload.itemname);
      setUpload(del);
      toast.success("Saved.");
    }).catch(function (error) {
      toast.warn('Error connecting to the server.');
    });
  }

  /* Generates the rows in the table that displays all the artworks awaiting approval from mods.
   */
  const renderBody = () => {
    //return uploads && uploads.map(({ id, itemname, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, tags, modifyingitems, itemfilename}) => {
    return uploads && uploads.map(({ id, itemname, uploader, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, medium, tags, modifications, modifyingitems, audioabout, videomesh, itemformat, itemfilename, license}) => {
        return (
            <tr key={id}>
                <td>{itemname}</td>
                <td>{artist}</td>
                <td>{new Date(created).toLocaleDateString()}</td>
                <td>{new Date(modified).toLocaleDateString()}</td>
                <td className='operation'><button onClick={()=>{history.push({pathname: itemformat === "video" ? "/videoeditor": "/editor", 
                  state: "review", 
                  name: itemname,
                  id: id,
                  bakedstate: baked, 
                  descstate: itemdesc,
                  iconstate: profilepic,
                  sizestate: size,
                  xValstate: xVal,
                  yValstate: yVal,
                  zValstate: zVal, 
                  xRotstate: xRot,
                  yRotstate: yRot,
                  zRotstate: zRot, 
                  tags: tags,
                  extension: itemformat,
                  videomesh: videomesh,
                  fnstate: itemfilename,
                  audiofilename: audioabout,
                  texture: texture,
                  modifications: modifications,
                  modifyingitems: modifyingitems,
                  uploadername: uploader,
                  medium: medium,
                  license: license,
                });}}><img src={process.env.PUBLIC_URL + '/view.png'} alt="Edit" width='15px'/></button></td>
                <td><button onClick={() => saveDecision(id, itemname, uploader, "true")}><img src={process.env.PUBLIC_URL + '/check.png'} alt="approve" width='15px'/></button></td>
                <td><button onClick={() => saveDecision(id, itemname, uploader, "false")}><img src={process.env.PUBLIC_URL + '/x.png'} alt="reject" width='15px'/></button></td>
            </tr>
        )
    })
  }
//<td className='operation'><button onClick={() => editInfo(itemname)}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>

  return (
      <div>
        <button className="menu-btn" onClick={() => setisMenu(!ismenu)}><img src={process.env.PUBLIC_URL + '/menu.png'} alt="Menu" width='35px'/></button>
        {ismenu &&  <Menu active={"review"} menuis={ismenu} setMenuis={setisMenu} handleClose={() => setisMenu(!ismenu)} />}
        <ToastContainer />
        <div>
          <button className="back-btn" onClick={()=>{history.goBack();}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
        </div> 
        <h1 className="title">Review Submissions</h1>
        <div className="panel">
          <table id='items'>
            <thead>
              <tr>{renderHeader()}</tr>
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
          </table>
        </div> 
      </div>
  );
}

export default Review;