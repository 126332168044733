import React, { useContext, useEffect, useState, useRef } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {isauth, usn} from '../Helper/Context';
import Menu from './Menu';
import Panel from './Panel';
import EditPanel from './EditPanel';
import Tag
 from './Tag';
//this uses the testing DBs. update to live DBs when pushing.

function Spaces() {
    const history = useHistory();
    const{ au } = useContext(isauth);
    const { mode } = useContext(usn);
    const [ismenu, setisMenu] = useState(false);
    useEffect(() => {
      if(au === "admin"){
        getitems();
      }
    }, [au]);
    const[spaceslist, setSpaceslist] = useState([]);
    const[exhibitionslist, setExhibitions] = useState(null);
    const[artistslist, setArtists] = useState(null);
    const[tagslist, setTags] = useState(null);
    const[spacesdisplay, setDisplay] = useState(true);
    const props = useRef({
        state: '',
        id: '',
        spacesname: '',
        location: '',
        shortdesc: '',
        longdesc: '',
        spacesicon: '',
        displaystatus: false
      }); 

    const[spacesiconurl, setspacesiconurl] = useState(process.env.PUBLIC_URL + '/select.png');
    const[selectedicon, setSelected]= useState();
    const[spacesicon, setSpacesicon] = useState(null);
    useEffect(() => {
        if(spacesicon && spacesicon.length > 0){
          var updtmessage = "Selected " + spacesicon[0].name;
          setSelected(updtmessage);
        } else {
          setSelected('');
        }
      }, [spacesicon]);

    /* Submits a form to the server to request information on all the spaces in the spaces db. Data is returned in JSON form.
     */
    const getitems = async() => {
      await axios({
        method: 'post',
        url:`https://sharedspaces-dev.usask.ca/${mode}/dbrequests`,
        headers: {'Content-Type': 'application/json'},
        data: { reqtype: "spaces"}
      }).then(function(response){
          setSpaceslist(response.data);
      }).catch(function (error) {
          toast.warn('Error connecting to the server.');
      });
    }

    /* Submits a form to the server to delete a space from the spaces db.
     * param string itemtodelete: name of space to delete.
     */
    const removeData = (itemtodelete) => { 
        var itemfilter = spaceslist.filter(item => itemtodelete === item.spacesname);
        let iconname = itemfilter[0].spacespic + '\tgalleriespic';
        axios({
            method: 'post',
            url: `https://sharedspaces-dev.usask.ca/${mode}/dashdelete`,
            headers: {'Content-Type': 'application/json'},
            data: {
            deletetype: "spacedelete",
            fndelete: '',
            dbitem: itemtodelete,
            icdelete: iconname,
            texdelete: '',
            id: itemfilter[0].id
        }})
        .then(function (response) {
            const del = spaceslist.filter(upload => itemtodelete !== upload.spacesname);
            setSpaceslist(del);
            toast.success(itemtodelete + ' Deleted');
            })
        .catch(function (error) {
            toast.warn('Delete failed.');
        });
    }

    /* Submits a form to the server to save changes to the fields in the spaces page's instance of EditPanel. Grabs the current values in all the fields.
     */
    const saveData = async() => {
        const tosave = new FormData();
        if (props.current.state === "new") {
          tosave.append('updatetype', "newspace");
          let arr = [props.current.spacesname, props.current.location, props.current.shortdesc, props.current.longdesc, spacesicon];
          let empty = (currentvalue) => currentvalue !== null && currentvalue.length > 0;
          if(!arr.every(empty)){
              toast.warn('Please ensure every input field is completed and an icon is selected.');
              return
            }
        } else {
          tosave.append('updatetype', "space");
        } 
        if (spacesicon && spacesicon.length > 0){
          tosave.append('spacesPic', spacesicon[0].name);
          tosave.append('spacefn', spacesicon[0]);
        }
        tosave.append('dblookup', 'spaces');
        tosave.append('spacesname', props.current.spacesname);
        tosave.append('location', props.current.location);
        tosave.append('shortdesc', props.current.shortdesc);
        tosave.append('longdesc', props.current.longdesc);
        tosave.append('exhibitions', exhibitionslist.join('\t'));
        tosave.append('artists', artistslist.join('\t'));
        tosave.append('artworkDesc', tagslist.join('\t'));
        tosave.append('display', props.current.displaystatus);
        tosave.append('id', props.current.id);
        await axios({
          method: 'post',
          url: `https://sharedspaces-dev.usask.ca/${mode}/dashmodify`, 
          headers: {
              'Content-Type': 'multipart/form-data',
            },
          data: tosave
        }).then(res => {
          if(spacesicon && (spacesicon[0].name !== props.current.spacespic)){
            var newprofpicadd = "https://sharedspaces-dev.usask.ca/app2/serve/galleriespic/" + res.data.append + spacesicon[0].name.replace(/\s/g, "");
            setspacesiconurl(newprofpicadd);
          }
          toast.success('Spaces info updated.');
          getitems();
          setSpacesicon(null);
        })
        .catch(err => {
          toast.warn('Save failed.');
        });
    }

    /* Generates the headers in the table that displays all the spaces in the spaces db.
     */
    const renderHeader = () => {
    let headerElement = ['Name', 'Location', 'Exhibitions', 'Tags', 'Display Status', 'Edit', 'Delete'];

    return headerElement.map((key, index) => {
        return <th key={index}>{key}</th>
    })
    }

    /* Generates the rows in the table that displays all the spaces in the spaces db.
     */
    const renderBody = () => {
    //return uploads && uploads.map(({ id, itemname, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, tags, modifyingitems, itemfilename}) => {
    return spaceslist && spaceslist.map(({ id, spacesname, location, short_desc, long_desc, exhibitions, artists, artworkDesc, spacespic, display_status}) => {
        return (
            <tr key={id}>
                <td>{spacesname}</td>
                <td>{location}</td>
                <td>{exhibitions}</td>
                <td>{artworkDesc}</td>
                <td>{display_status}</td>
                <td className='operation'><button onClick={()=>{
                  if (artists.length > 0) {
                    setArtists(artists.trim().split('\t'));
                  }
                  if (exhibitions.length > 0) {
                    setExhibitions(exhibitions.trim().split('\t'));
                  }
                  if (artworkDesc.length > 0) {
                    setTags(artworkDesc.trim().split('\t'));
                  }
                  props.current.state = 'edit';
                  props.current.id = id;
                  props.current.spacesname = spacesname;
                  props.current.location = location;
                  props.current.shortdesc = short_desc;
                  props.current.longdesc = long_desc;
                  props.current.spacesicon = spacespic;
                  props.current.displaystatus = display_status;
                  var spacesurl = "https://sharedspaces-dev.usask.ca/app2/serve/galleriespic/" + spacespic;
                  setspacesiconurl(spacesurl);
                  setDisplay(false);
                }}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>
                <td className='operation'><button onClick={() => removeData(spacesname)}><img src={process.env.PUBLIC_URL + '/delete.png'} alt="Edit" width='15px'/></button></td>
            </tr>
        )
    })
    }

  return (
      <div>
        <button className="menu-btn" onClick={() => setisMenu(!ismenu)}><img src={process.env.PUBLIC_URL + '/menu.png'} alt="Menu" width='35px'/></button>
        {ismenu &&  <Menu active={"spaces"} menuis={ismenu} setMenuis={setisMenu} handleClose={() => setisMenu(!ismenu)} />}
        <ToastContainer />
        <div>
          <button className="back-btn" style={!spacesdisplay ? { marginTop: 'calc(3vh + 35px)' } : { marginTop: '0' }} onClick={()=>{if (spacesdisplay){history.goBack();} else {setDisplay(true); setSpacesicon(null); setspacesiconurl(process.env.PUBLIC_URL + '/select.png');}}}><img src={process.env.PUBLIC_URL + '/left-arrow.png'} alt="Back" width='32px'/></button>
        </div> 
        {spacesdisplay ? <h1 className="title">Spaces</h1> : <h1 className="title">Edit Space</h1>}
        {spacesdisplay &&  <Panel generateHeader={renderHeader} generateBody={renderBody} />}
        {spacesdisplay && <button className="btn" onClick={()=>{props.current.state = "new"; setDisplay(false);}}>Create New Space</button>}
        {!spacesdisplay && <EditPanel 
            content={<>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-name" >Name</label>
                    <input type="text" className="profiletextinput" defaultValue={props.current.state === "edit" ? props.current.spacesname : ''} id="spaces-name" onChange={e => props.current.spacesname = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-location" >Location</label>
                    <input type="text" className="profiletextinput" defaultValue={props.current.state === "edit" ? props.current.location : ''} id="spaces-location" onChange={e => props.current.location = e.target.value} required/>
                </div>
                <div className="div-padding-pic">
                    <p className="bold-label" >Artist Profile Pic</p>
                    <div className="profilepiccontainer">
                        <input type="file" id="profile-pic" accept="image/png, image/jpeg" onChange={e => setSpacesicon(e.target.files)} required/>
                        <label className="profilefileinput" for="profile-pic"><img src={spacesiconurl} alt="Profile icon preview." width="100px"/></label>
                        <p className="inputtext">{selectedicon}</p>
                    </div>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-bio-short" >Short Description</label>
                    <textarea className="profilelonginput" defaultValue={props.current.state === "edit"? props.current.shortdesc : ''} id="spaces-bio-short" onChange={e => props.current.shortdesc = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-bio-long" >Long Description</label>
                    <textarea className="profilelonginput" defaultValue={props.current.state === "edit"? props.current.longdesc : ''} id="spaces-bio-long" onChange={e => props.current.longdesc = e.target.value} required/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-artists" >Artists</label>
                    <Tag tagitems={artistslist} updateTags={setArtists} status={props.current.state} displayStatus={spacesdisplay}/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-exhibitions" >Exhibitions</label>
                    <Tag tagitems={exhibitionslist} updateTags={setExhibitions} status={props.current.state} displayStatus={spacesdisplay}/>
                </div>
                <div className="div-padding">
                    <label className="bold-label" for="spaces-tags" >Tags</label>
                    <Tag tagitems={tagslist} updateTags={setTags} status={props.current.state} displayStatus={spacesdisplay}/>
                </div>
            </>}
        />}
        {!spacesdisplay && <button className="btn" onClick={()=> saveData()}>Save</button>}
      </div>
  );
}

export default Spaces;