import React, {useState, useEffect, useRef } from 'react';
import './Editor.css';

function Jskeying_VideoEditor() {
  const videoBox = useRef(null);
  const videoBox2 = useRef(null);
  const videoContent = useRef(null);
  useEffect(()=>{
    //window.addEventListener('click', playpause);
    //videoContent.addEventListener('play', process);
  });

  //react has had a bug for the past few years where you can't mute video elements so until that gets fixed, a workaround like this is needed.
  const [muted, setMuted] = useState(null);
  useEffect(()=>{
    if (muted !== null){
      videoContent.current.defaultMuted = muted;
      videoContent.current.muted = muted;
    }
  }, [muted]);

  const playpause = (e) => {
    /*if(videoBox2.current !== null && videoBox2.current.contains(e.target)){
      if (videoContent.current.playing){
        videoContent.current.pause();
      } else {
        videoContent.current.play();
        processing();
      }
    }*/
    if (videoContent.current.paused){
      videoContent.current.play();
      processing();
    } else {
      videoContent.current.pause();
    }
  }

  const processing = () => {
    videoBox.current.getContext('2d').drawImage(videoContent.current, 0, 0, videoContent.current.width, videoContent.current.height);
    let frame = videoBox.current.getContext('2d').getImageData(0, 0, videoContent.current.width, videoContent.current.height);
    /*for(let i = 0; i<frame.data.length/4; i++){
      let r = frame.data[i * 4 + 0];
      let g = frame.data[i * 4 + 1];
      let b = frame.data[i * 4 + 2];
    }*/
    videoBox2.current.getContext('2d').putImageData(frame, 0, 0);
    setTimeout(processing, 0);
  }

  return (
    <div>
      <video width="896" height="414" ref={videoContent} id="video" src={process.env.PUBLIC_URL + '/greenscreen3_30.mp4'}/>
      <canvas width="896" height="414" ref={videoBox} id="outputCanvas"/>
      <canvas width="896" height="414" ref={videoBox2} id="outputCanvas2"/>
      <button onClick={()=>{setMuted(!muted)}}>Mute</button>
      <button onClick={()=>{playpause();}}>Play</button>
    </div>
  );
}
export default Jskeying_VideoEditor;
