import React, {useContext, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';  
//import ButtonGoogleLogout from './ButtonGoogleLogout';
import './Menu.css';  
import {isauth} from '../Helper/Context';
import { useGoogleLogout } from 'react-google-login';
import { toast } from 'react-toastify';

const Menu = ({active, menuis, setMenuis}) => {
  const history = useHistory();
  const { au } = useContext(isauth);
  //const active = prop.content.props.children;
  const menuRef = useRef(null);
  const onLogoutSuccess = () => {
    history.push('/');
  }
  const onFailure = () => {
    toast.warn("Failed to logout!");
  }
  const { signOut } = useGoogleLogout({
    onLogoutSuccess,
    onFailure
  });

  useEffect(()=>{
    if (menuis === true){
      window.addEventListener('click', pageClicked);
      window.addEventListener('scroll', pageClicked);
    } else if (menuis === false) {
      window.removeEventListener('click', pageClicked);
      window.removeEventListener('scroll', pageClicked);
    }
  }, [menuis]);
  
  /* Method that is triggered when clicks happen within the page. It checks whether the click happened within the menu instance. If not, menu is closed.
   * param event e: clicking event item.
   */
  const pageClicked = (e) => {
    if (menuRef.current !== null && !menuRef.current.contains(e.target)) {
      setMenuis(!menuis);
    }
  };

  /* Populates menu with the different buttons that can be pressed to navigate around. Different buttons are available to admin and non-admin accounts.
   */
  const renderMenuitems = () => {
    //let itemElements = [['dashboard', 'Home'], ['profile', 'Edit Profile'], ['logout', 'Logout']].filter(item => item[0] !== active);
    let itemElements = [['dashboard', 'Home'], ['profile', 'Edit Profile']].filter(item => item[0] !== active);
    let adminElements = [['review', 'Review Submissions'], ['spaces', 'Spaces'], ['exhibitions', 'Exhibitions'], ['testpage', 'Test Page'], ['jskey', 'JSKey']].filter(item => item[0] !== active);
    if (au === "admin"){
      let filteredElements = itemElements.concat(adminElements);
      return filteredElements.map((pages) => { 
        if(pages[0] === 'logout'){
          //return <li><ButtonGoogleLogout/></li>
          return <li><button onClick={()=>{signOut()}}>{pages[1]}</button></li>
        } else {
          return <li><button onClick={()=>{history.push('/'+ pages[0]);}}>{pages[1]}</button></li>
        }
    })
    } else {
      return itemElements.map((pages) => { 
        if(pages[0] === 'logout'){
          //return <li><ButtonGoogleLogout/></li>
          return <li><button onClick={()=>{signOut()}}>{pages[1]}</button></li>
        } else {
          return <li><button onClick={()=>{history.push('/'+ pages[0]);}}>{pages[1]}</button></li>
        }
    })
    }
}

  return (
    <div className="menu-popup">
      <div ref={menuRef} className="menu">
          <ul>
            {renderMenuitems()}
          </ul>
      </div>
    </div>
  );
};
 // <span className="close-icon" onClick={props.handleClose}>x</span>
export default Menu;
/*
return (
  <div className="menu-popup">
    <div ref={menuRef} className="menu">
        <ul>
          {active !== "dashboard" ? <li><button onClick={()=>{history.push('/dashboard');}}>Home</button></li> : null}
          {active !== "profile" ? <li><button onClick={()=>{history.push('/profile');}}>Edit Profile</button></li> : null}
          {active !== "review" && au === "admin" ? <li><button onClick={()=>{history.push('/review');}}>Review Submissions</button></li> : null}
          {active !== "spaces" && au === "admin" ? <li><button onClick={()=>{history.push('/spaces');}}>Spaces</button></li> : null}
          {active !== "exhibitions" && au === "admin" ? <li><button onClick={()=>{history.push('/exhibitions');}}>Exhibitions</button></li> : null}
          {active !== "testpage" && au === "admin" ? <li><button onClick={()=>{history.push('/testpage');}}>Test Page</button></li> : null}
        </ul>
    </div>
  </div>
);

{renderMenuitems()}
*/