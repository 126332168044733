import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import {usn, isauth} from '../Helper/Context';
import {usn} from '../Helper/Context';
import Menu from './Menu';
import Panel from './Panel';
import SelectionMenu from './SelectionMenu';
import BigTextPopup from './BigTextPopup';

function Dashboard() {
  const { user } = useContext(usn);
  const { mode } = useContext(usn);
  //const { au } = useContext(isauth);
  const [ismenu, setisMenu] = useState(false);
  const [isselection, setisSelection] = useState(false);
  const [privacypolicy, setprivacypolicy] = useState(false);
  //const{prof, setprof} = useContext(profile);
  //console.log(user);
  //const[token, setToken] = useState({username});
  useEffect(() => {
    if(user){
      getitems();
      //getprofile();
    }
  }, [user]);
  const[uploads, setUpload] = useState([]);
  let history = useHistory();
  /*
  if(!token) {
    return <Login thispass={setToken} /> //blue is logins settoken var, yellow is app js' settoken
  }
*/

  /* Submits a form to the server to request a json of all the items uploaded by the username currently logged in.
   */
  const getitems = () => {
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/dbrequests`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        reqtype: "uploadedby",
        findthis: user
      }
    }).then(function(response){
      /*var items = uploads;
      for (var x = 0; x < response.data.length; x++){
        items.push(response.data[x].itemname);
      }
      setUpload(items);
      */
     setUpload(response.data);
    }).catch(function (error) {
      toast.warn('Error connecting to the server.');
    });
  } 
/*
  const getprofile = () => {
    axios({
      method: 'post',
      url: 'https://sharedspaces-dev.usask.ca/app2/artistcheck',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        findthis: user
      }
    }).then(function(response){
        setprof(response.data);
      } 
    ).catch(function (error) {
      toast.warn('Server error.');
    });
  }
*/
  const old_removeData = (itemtodelete) => { 
    let itemfilter = uploads.filter(item => itemtodelete === item.itemname);
    let itemname = itemfilter[0].itemfilename + '\titems';
    let iconname = itemfilter[0].profilepic + '\tartworkspic';
    let texturename = itemfilter[0].itemfilename.substring(0, itemfilter[0].itemfilename.indexOf(".")) + '.mtl\titems';
    let audioname = "";
    if (itemfilter[0].audioabout !== 'undefined' && itemfilter[0].audioabout.length > 0){
      audioname = itemfilter[0].audioabout + '\taudiofiles';
    }
    if (itemfilter[0].texture.length > 1 && itemfilter[0].texture !== "undefined"){
      texturename = texturename + '/~' + itemfilter[0].texture + '\titems';
    }
    axios({
      method: 'post',
      url: 'https://sharedspaces-dev.usask.ca/testingroute/dashdelete',
      headers: {'Content-Type': 'application/json'},
      data: {
        deletetype: "itemdelete",
        id: itemfilter[0].id,
        fndelete: itemname,
        dbitem: itemtodelete,
        icdelete: iconname,
        texdelete: texturename,
        audiodelete: audioname
      } }).then(function (response) {
        const del = uploads.filter(upload => itemtodelete !== upload.itemname);
        setUpload(del);
        toast.success(itemtodelete + ' Deleted');
      })
      .catch(function (error) {
        toast.warn('Delete failed.');
      });
    //const del = uploads.filter(upload => itemtodelete !== upload.itemname);
    //setUpload(del);
  }

  /* Submits a form to the server for an item to be deleted.
   * param string itemtodelete: name of item to delete.
   */
  const removeData = (itemtodelete) => { 
    let itemfilter = uploads.filter(item => itemtodelete === item.itemname);
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/dashdelete`,
      headers: {'Content-Type': 'application/json'},
      data: {
        deletetype: "artwork",
        id: itemfilter[0].id
      } }).then(function (response) {
        const del = uploads.filter(upload => itemtodelete !== upload.itemname);
        setUpload(del);
        toast.success(itemtodelete + ' Deleted');
      })
      .catch(function (error) {
        toast.warn('Delete failed.');
      });
    //const del = uploads.filter(upload => itemtodelete !== upload.itemname);
    //setUpload(del);
  }

  /* Submits a form to the server for an artwork to be reviewed by mods.
   * param string itemtosubmit: name of item to submit for review.
   */
  const submitforApproval = (itemtosubmit) => { 
    let itemfilter = uploads.filter(item => itemtosubmit === item.itemname);
    axios({
      method: 'post',
      url: `https://sharedspaces-dev.usask.ca/${mode}/approvalsubmission`,
      headers: {'Content-Type': 'application/json'},
      data: {
        itemid: itemfilter[0].id
      } }).then(function (response) {
        getitems();
        toast.success(itemtosubmit + ' submitted.');
      })
      .catch(function (error) {
        toast.warn('Submit failed.');
      });
  }

  /* Generates the headers in the table that displays all the artworks uploaded by the logged in account.
   */
  const renderHeader = () => {
    let headerElement = ['Item Name', 'Artist', 'Created', 'Modified', 'Edit', 'Delete', ' ']

    return headerElement.map((key, index) => {
        return <th key={index}>{key}</th>
    })
  }

  /* Generates the rows in the table that displays all the artworks uploaded by the logged in account.
   */
  const renderBody = () => {
    //return uploads && uploads.map(({ id, itemname, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, tags, modifyingitems, itemfilename}) => {
    return uploads && uploads.map(({ id, itemname, size, xVal, yVal, zVal, xRot, yRot, zRot, artist, itemdesc, profilepic, baked, created, modified, texture, medium, tags, modifications, modifyingitems, audioabout, videomesh, itemformat, itemfilename, review_status, license}) => {
        return (
            <tr key={id}>
                <td>{itemname}</td>
                <td>{artist}</td>
                <td>{new Date(created).toLocaleDateString()}</td>
                <td>{new Date(modified).toLocaleDateString()}</td>
                <td className='operation'><button onClick={()=>{history.push({pathname: itemformat === "video" ? "/videoeditor": "/editor", 
                  state: "edit",
                  id: id,
                  name: itemname, 
                  bakedstate: baked, 
                  descstate: itemdesc,
                  iconstate: profilepic,
                  sizestate: size,
                  xValstate: xVal,
                  yValstate: yVal,
                  zValstate: zVal,
                  xRotstate: xRot,
                  yRotstate: yRot,
                  zRotstate: zRot,
                  tags: tags,
                  extension: itemformat,
                  videomesh: videomesh,
                  fnstate: itemfilename,
                  audiofilename: audioabout,
                  texture: texture,
                  modifications: modifications,
                  modifyingitems: modifyingitems,
                  medium: medium,
                  license: license,
                });}}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>
                <td className='operation'><button onClick={() => removeData(itemname)}><img src={process.env.PUBLIC_URL + '/delete.png'} alt="Edit" width='15px'/></button></td>
                {review_status === "false" ? <td className='operation'><button onClick={() => submitforApproval(itemname)}>Submit</button></td> : null}
            </tr>
        )
    })
  }

  //<td className='operation'><button onClick={() => editInfo(itemname)}><img src={process.env.PUBLIC_URL + '/edit.png'} alt="Edit" width='15px'/></button></td>
  /*
    Multiple upload button setup
    <button className="btn" onClick={()=>setisSelection(!isselection)}>Upload New Item</button>
    {isselection && <SelectionMenu active={"dashboard"} menuis={isselection} setMenuis={setisSelection} handleClose={() => setisSelection(!isselection)} />}
   */
  return (
      <div>
        <button className="menu-btn" onClick={() => setisMenu(!ismenu)}><img src={process.env.PUBLIC_URL + '/menu.png'} alt="Menu" width='35px'/></button>
        <button className="btn" onClick={()=>{history.push({pathname: '/editor', state: 'new' });}}>Upload New Item</button>
        <h1 style={{marginTop:'3.5vh'}} className="title">Uploads</h1>
        {ismenu &&  <Menu active={"dashboard"} menuis={ismenu} setMenuis={setisMenu} handleClose={() => setisMenu(!ismenu)} />}
        <ToastContainer />
        <Panel generateHeader={renderHeader} generateBody={renderBody} />
        <button id="privacy-button" onClick={()=> setprivacypolicy(!privacypolicy)}>See Privacy Policy</button>
        {privacypolicy && <BigTextPopup  
          content={<>
            <h1>Shared Spaces Privacy Policy</h1>
            <p>Shared Spaces (referred to as “Shared Spaces”, “we”, “us”, or “our”) provides digital services exploring how augmented reality (AR) can create opportunities for connection through art.</p>
            <p>Please read our Privacy policy as it discloses the privacy practices for all Shared Spaces services. This Privacy Policy describes how your (referred to as the “user”, “you”, or “your”) information is collected, used, and shared when you use any of the Shared Spaces services.</p>
            <p className="better-h2">Personal information we collect</p>
            <p>When using the Shared Spaces services, we automatically collect certain information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. As you browse the Shared Spaces App and Web Uploader, we collect information about the individual pages or artworks that you view and information about how you interact with the Shared Spaces App. We refer to this automatically-collected information as “Device Information”.</p>
            <p>Additionally, if you register for a Shared Spaces Web Uploader account, we collect some personal information including your name, email address, username, images, and creative work. </p>
            <p>We collect Device Information using the following technologies:</p> 
            <ul className='privacy-ul'>
              <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. </li>
              <li>“Log files” track actions occurring on the App, and collect data including your IP address, browser type, Internet service provider, and date/time stamps. </li>
              <li>“Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the App.</li>
            </ul>  
            <p className="better-h2">How do we use your personal information?</p>  
            <p>We use the Device Information that we collect to improve and optimize the Shared Spaces App (for example, by generating analytics about how users browse and interact with creative work, and to assess the success of our marketing and advertising campaigns). We use the personal information that we collect for purposes of providing the service, responding to inquiries, and other purposes users would reasonably expect.</p>
            <p className="better-h2">Sharing you personal Information</p>  
            <p>We do not share your Personal Information with third parties unless compelled to do so to comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful requests for information we receive, or to otherwise protect our rights.</p>    
            <p className="better-h2">Your rights </p>
            <p>Depending on where you reside, you have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. If you reside outside of Canada, please note that your information will be transferred outside of the country as Shared Spaces is located in Canada.</p>
            <p className="better-h2">Changes </p>   
            <p>We may update this privacy policy to reflect changes to our practices or for other operational, legal, or regulatory reasons. Your continued use of the services indicates your acceptance of this Privacy Policy as amended from time to time.</p>
            <p className="better-h2">Links To Third Parties</p>   
            <p>The Shared Spaces website contains links to other sites. While Shared Spaces endeavours to link to other sites that have similar high standards and respect for privacy, we are not responsible for the privacy practices or the content of other sites.</p>
            <p className="better-h2">Shared Spaces contact information</p>
            <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e‑mail at sharedspaces.sk@usask.ca or by mail using the details provided below:</p>
            <p>Shared Spaces
              <br/>c/o University of Saskatchewan Art Galleries and Collection
              <br/>Peter MacKinnon Building, Ground Level
              <br/>107 Administration Place, University of Saskatchewan
              <br/>Saskatoon, SK Canada S7N 5A2 
            </p>
          </>}
          handleClose={() => {setprivacypolicy(!privacypolicy);}}
        />}
      </div>
  );
}

export default Dashboard;

//old "upload new item" button <button className="btn" onClick={()=>{history.push({pathname: "/editor", state: "new"});}}>Upload New Item</button>
/*
<div>
<button className="btn" onClick={()=>{history.push("/profile");}}>Edit Profile</button>
</div> 

{au === "admin" ? <button className="btn-left" onClick={()=>{history.push({pathname: "/review", accstate: "admin"});}}>Review Submissions</button> : null}
*/
//<button className="btn-left" onClick={()=>{history.push("/testpage");}}>Testpage</button>

//<button className="btn" onClick={()=>{history.push("/editor");}}>Upload New Item</button>
/* const checkforentries = () => {
    const infoForm = new FormData();
    infoForm.append('uploader', token);
    axios.post(
      "https://sharedspaces-dev.usask.ca/uploadconnect/checkhistory.php",
      infoForm
    ).then(function (response) {
      console.log(response);
      console.log(response[0]);
    }).catch(err => {
      toast.warn('Server did an oops');
      console.log(err);
    });
  }

  //checkforentries();

  const renderUploads = () => {
    var itemanem = 'useroooo';
    axios({
      method: 'post',
      url: 'https://sharedspaces-dev.usask.ca/app2/databasepull',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        findthis: itemanem
      }
    }).then(function(response){
      var items = uploads;
      for (var x = 0; x < response.data.length; x++){
        items.push(response.data[x].itemname);
        console.log(response.data[x].itemname)
      }
      setUpload(items);
      console.log(uploads);
    }).catch(function (error) {
      toast.warn('Server did an oops');
      console.log(error);
    });
  }

  const originalrender = () => {
    return uploads.map((student, index) => {
      const {id, name, modified} = student
      return (
        <tr key={id}>
           <td>{id}</td>
           <td>{name}</td>
           <td>{modified}</td>
        </tr>
     )
    })
  }

  const renderRows = () => {
    return uploads.map((itemline, index) => {
      return (
        <tr key={index}>
           <td>{itemline}</td>
        </tr>
     )
    })
  } */