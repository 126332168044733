import React from 'react';  
import './BigTextPopup.css';  

const BigTextPopup = props => {
  return (
    <div className="privacy-box">
      <div id="active-popup-box" className="popup-text">
        <span className="privacy-close-icon" onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};
 
export default BigTextPopup;