import { GoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";

const clientId = "69001129189-f1ct2pdp8dovm8iuqfod57evbp3ukoel.apps.googleusercontent.com";

function ButtonGoogleLogout() {
    const history = useHistory();
    const onSuccess = () => {
        history.push('/');
        console.log("Log out successful!");
    }
    
    return(
        <div id="signOutButton">
            <GoogleLogout
                clientId={clientId}
                buttonText={"Logout"}
                onLogoutSuccess={onSuccess}
            />
        </div>
    )
}

export default ButtonGoogleLogout;