import React, {useRef, useEffect} from 'react';  
import './Tag.css';  

const Tag = ({tagitems, updateTags, status, displayStatus}) => {
  //const [tagitems, updateTags] = useState(["1button", "2button", "3button"]);
  const parentContainer = useRef(null);
  const inputContainer = useRef(null);
  const tagContainer = useRef(null);

  useEffect(()=>{
    if (displayStatus === false){
      window.addEventListener('click', focusClick);
    } else if (displayStatus === true){
      window.removeEventListener('click', focusClick);
    }
  }, [displayStatus]);

  if (status === "new"){
    updateTags(null);
  }

  /* Method used to bring the tags panel into focus.*/
  const focusClick = (e) => {
    if (parentContainer.current !== null && e.target === parentContainer.current) {
      inputContainer.current.focus();
    }
  };

  /* Checks if the keypress was an enter button and if so, adds the in progress tag to the list of tags.
   * param event keypress: key that was pressed.
   */
  const addnewtag = (keypress) => {
    if (keypress.keyCode === 13){
      let trimmedString = (keypress.target.value).replace(/\t+/g, " ");
      updateTags([...tagitems, trimmedString]);
    }
  }

  /* Deletes a tag from the list of tags,
   * param string tagname: name of tag to be deleted.
   */
  const deletetag = (tagname) => {
    console.log(tagname);
    let del = tagitems.filter(item => item !== tagname);
    updateTags(del);
    console.log(del);
  }

  /* Updates the tag once it's been edited.
   */
  const updatetaginfo = (oldtext, newtext) => {
    let updatedvalues = tagitems.map(current => {
      if (current === oldtext){
        return newtext;
      }
      return current;
    });
    updateTags(updatedvalues);
  }

  /* Generates the tags in the tag list to be displayed in the tag container.
   */
  const populatetags = () => {
    if (tagitems !== null && tagitems.length > 0){
      return tagitems.map(item => {
        return (
          <div className="tag-container" ref={tagContainer}>
            <button className="texedit-btn">
            <input className="btninput" onChange={e=> updatetaginfo(item, e.target.value)} type="text" value={item}/>
            </button>
            <button className="btnimg" onClick={()=> deletetag(item)}>
              <img src={process.env.PUBLIC_URL + '/x.png'} width="15" height="15" alt="Delete tag"/>
            </button>
          </div>
        )
      })
    }
    
  }

  return (
    <div className="parent-tag" ref={parentContainer}>
      {populatetags()}
      <input ref={inputContainer} onKeyDown={e => addnewtag(e)} className="tag-input" type="text"/>
    </div>
  );
};
 
export default Tag;